import React from "react";
import { User } from "../types/identities";
import LogoutButton from "../components/buttons/LogoutButton";
import { Container } from "react-bootstrap";

function AlreadyLoggedIn({ user }: { user: User }) {
  return (
    <Container className="pt-5 space-children-4">
      <h1>Already Logged In</h1>
      <p>You are already logged in as {user.email} </p>
      <LogoutButton />
    </Container>
  );
}

export default AlreadyLoggedIn;
