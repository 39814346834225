import { Container } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";

import "./Loading.scss";

function Loading() {
  return (
    <Container className="pt-5">
      <div className="justify-content-center d-flex">
        <Spinner
          className="spinner-large"
          animation="border"
          role="status"
          variant="primary"
        >
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    </Container>
  );
}

export default Loading;
