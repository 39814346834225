import React from "react";
import { Badge } from "react-bootstrap";
import BalanceContext from "../../contexts/BalanceContext";

function BalanceBadge({ ...props }: { [key: string]: any }) {
  const { balance: creditBalance } = React.useContext(BalanceContext);

  return (
    <Badge bg={creditBalance > 100 ? "success" : "secondary"} {...props}>
      Balance: ${creditBalance / 100}
    </Badge>
  );
}

export default BalanceBadge;
