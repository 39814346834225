import React from "react";
import { Container } from "react-bootstrap";

function Page404() {
  return (
    <Container className="py-5">
      <div className="text-center space-children-3">
        <h1>404</h1>
        <p>Page not found</p>
      </div>
    </Container>
  );
}

export default Page404;
