import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import LogoutButton from "../components/buttons/LogoutButton";
import axios, { AxiosResponse } from "axios";
import { DEFAULT_CONFIG } from "../constants";
import { toast } from "react-toastify";
import { Template } from "../types/template";
import { Link } from "react-router-dom";
import { TemplateUnmodifiableParent } from "../components/einvtemplateshared/EinvitationTemplateView";
import AddCredits from "../components/stripe/AddCredits";
import ProfileSidebar from "../components/sidebars/ProfileSideBar";

function Profile() {
  const [templates, setTemplates] = React.useState<Template[]>([]);
  React.useEffect(() => {
    const fetchTemplates = async () => {
      try {
        const res: AxiosResponse<{ templates: Template[] }> = await axios.get(
          "/templates/?owner=team",
          DEFAULT_CONFIG
        );
        setTemplates(res.data.templates);
      } catch (error) {
        // Handle any errors
        toast.error(`Error fetching templates ${error}`);
      }
    };

    fetchTemplates();
  }, []);

  return (
    <div className="d-flex">
      <ProfileSidebar />
      <Container className="pt-5">
        <h1 style={{ display: "none" }}>Profile</h1>

        <Row>
          <Col xs={12} md={6}>
            <h2 className="mb-4">Add Credits</h2>

            <AddCredits />
          </Col>
        </Row>

        <Row className="gy-5 my-5">
          <h2>Team templates</h2>
          {templates.length === 0 && (
            <p>
              Your team has not created any templates!{" "}
              <Link to="/">Create one now</Link>
            </p>
          )}
          {templates.map((template) => (
            <Col xs={12} md={4} key={template.id}>
              <p className="text-center mb-4">{template.name}</p>
              <Link to={`/templates/view/${template.id}`}>
                <Button variant="outline-primary" className="w-100">
                  <TemplateUnmodifiableParent template={template} />
                </Button>
              </Link>
            </Col>
          ))}
        </Row>
        <Row className="mb-5">
          <Col xs={12} md={6}>
            <LogoutButton />
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Profile;
