import React from "react";
import { useParams, Link } from "react-router-dom";
import axios, { AxiosResponse } from "axios";
import { Button, Col, Container, Row } from "react-bootstrap";
import { toast } from "react-toastify";

import { Template } from "../types/template";
import { DEFAULT_CONFIG, PREVIEW_TEMPLATE } from "../constants";
import AuthContext from "../contexts/AuthContext";
import TemplateUnmodifiable from "../components/einvtemplateshared/EinvitationTemplateView";
import useSetOwnerIfNone from "../hooks/useSetOwnerIfNone";
import { posthog } from "posthog-js";

function ViewTemplate() {
  const { id } = useParams<{ id: string }>();
  const { user } = React.useContext(AuthContext);

  const [template, setTemplate] = React.useState<Template | null>(null);

  useSetOwnerIfNone(template, setTemplate);

  React.useEffect(() => {
    const fetchTemplate = async () => {
      try {
        const res: AxiosResponse<Template> = await axios.get(
          `/templates/${id}/`,
          DEFAULT_CONFIG
        );
        setTemplate(res.data);
        posthog.capture(PREVIEW_TEMPLATE, { user: user.id });
      } catch (error) {
        toast.error("Error fetching template");
      }
    };
    fetchTemplate();
  }, [id, user.id]);

  if (!template) {
    return <div>No template</div>;
  }

  const templateHasNoOwner = template.creator_team_id == null;
  const userIsOwner = user && user.team_id === template.creator_team_id;

  return (
    <div className="pt-5 pb-5 text-center">
      <h1 className="mb-3">View Template</h1>
      {templateHasNoOwner ? (
        <Link to={`/sign-up/?template_id=${template.id}`}>
          <Button
            variant="primary"
            className="fixed-button glisten scale-up-on-focus"
          >
            Sign up then Create invitations
          </Button>
        </Link>
      ) : (
        userIsOwner && (
          <Link to={`/templates/create_invitations/${template.id}/`}>
            <Button
              variant="primary"
              className="fixed-button glisten scale-up-on-focus"
            >
              Create invitations
            </Button>
          </Link>
        )
      )}

      <p className="mb-5">{template.name}</p>

      <Container>
        <Row className="gy-3 justify-content-center">
          <Col xs={10}>
            <TemplateUnmodifiable data={template} />
          </Col>

          {(templateHasNoOwner || userIsOwner) && (
            <Col xs={10}>
              <Link
                to={`/templates/change/${template.id}/`}
                className="text-decoration-none"
              >
                <div className="d-grid gap-2">
                  <Button variant="secondary">Edit</Button>
                </div>
              </Link>
            </Col>
          )}
        </Row>
      </Container>
    </div>
  );
}

export default ViewTemplate;
