import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import LogRocket from "logrocket";
import axios, { AxiosResponse } from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as RPS from "react-pro-sidebar";

import Header from "./components/nav/Header";
import ErrorBoundary from "./components/errors/ErrorBoundary";

import CreateTemplate from "./pages/CreateTemplate";
import ModifyTemplate from "./pages/ModifyTemplate";
import ViewTemplate from "./pages/ViewTemplate";
import Login from "./pages/Login";
import SignUp from "./pages/SignUp";
import Profile from "./pages/Profile";
import AlreadyLoggedIn from "./page_fallbacks/AlreadyLoggedIn";
import Page404 from "./page_fallbacks/Page404";
import Loading from "./page_fallbacks/Loading";

import AuthContext from "./contexts/AuthContext";
import { DEFAULT_CONFIG, EMPTY_USER, PROFILE_PAGE } from "./constants";
import { User } from "./types/identities";

import "./App.scss";
import CreateInvitations from "./pages/CreateInvitations";
import Guarantee from "./pages/Guarantee";
import SpecialOffer from "./components/modal/SpecialOffer";
import Einvitation from "./pages/Einvitation";
import Payments from "./pages/Payments";
import PaymentSuccessful from "./pages/PaymentSuccessful";
import MakeStripePayment from "./pages/MakeStripePayment";
import Team from "./pages/Team";
import { TeamPayments } from "./types/payments";
import BalanceContext from "./contexts/BalanceContext";

function withErrorBoundary(children: React.ReactNode) {
  return <ErrorBoundary>{children}</ErrorBoundary>;
}

function RouteMustBeLoggedOut({ children }: { children: JSX.Element }) {
  const { user, loading } = React.useContext(AuthContext);
  if (loading) {
    return <Loading />;
  }
  if (user.email) {
    return <AlreadyLoggedIn user={user} />;
  }
  return children;
}

function RouteMustBeLoggedIn({ children }: { children: JSX.Element }) {
  const { user, loading } = React.useContext(AuthContext);
  if (loading) {
    return <Loading />;
  }
  if (!user.email) {
    toast.error("You must be logged in to use that page");
    return <Navigate to="/login" />;
  }
  return children;
}

function App() {
  const [user, setUser] = React.useState<User>(EMPTY_USER);

  const [loading, setLoading] = React.useState(true);
  React.useEffect(() => {
    const fetchUser = async () => {
      try {
        const res: AxiosResponse<{ user: User }> = await axios.get(
          "/identities/user/",
          DEFAULT_CONFIG
        );
        setUser(res.data.user);
        LogRocket.identify(res.data.user.id, {
          name: `${res.data.user.first_name} ${res.data.user.last_name}`,
          email: res.data.user.email,
        });
      } catch (e: any) {
        if (e.response.status !== 401) {
          toast.error("Error fetching user");
        }
      }
      setLoading(false);
    };
    fetchUser();
  }, []);

  const [creditBalance, setCreditBalance] = React.useState(0);
  React.useEffect(() => {
    const fetchTeamPayments = async () => {
      try {
        const res: AxiosResponse<TeamPayments> = await axios.get(
          "/payments/team-payments/",
          DEFAULT_CONFIG
        );
        setCreditBalance(res.data.balance);
      } catch (error) {
        // Handle any errors
        // show error if not 403
        // toast.error(`Error fetching team payments ${error}`);
      }
    };
    fetchTeamPayments();
  }, []);

  return (
    <div className="App">
      <AuthContext.Provider
        value={{
          user: user,
          setUser: setUser,
          loading: loading,
        }}
      >
        <BalanceContext.Provider
          value={{
            balance: creditBalance,
            setBalance: setCreditBalance,
          }}
        >
          <ToastContainer autoClose={5000} />
          <BrowserRouter>
            <Header />
            <RPS.ProSidebarProvider>
              <Routes>
                <Route
                  path="/invitations/:id"
                  element={withErrorBoundary(<Einvitation />)}
                />

                <Route
                  path="/sign-up"
                  element={
                    <RouteMustBeLoggedOut>
                      <>
                        <SignUp />
                      </>
                    </RouteMustBeLoggedOut>
                  }
                />
                <Route
                  path="/login"
                  element={withErrorBoundary(
                    <RouteMustBeLoggedOut>
                      <Login />
                    </RouteMustBeLoggedOut>
                  )}
                />
                <Route
                  path={PROFILE_PAGE}
                  element={withErrorBoundary(
                    <RouteMustBeLoggedIn>
                      <Profile />
                    </RouteMustBeLoggedIn>
                  )}
                />
                <Route
                  path={"/team"}
                  element={withErrorBoundary(
                    <RouteMustBeLoggedIn>
                      <Team />
                    </RouteMustBeLoggedIn>
                  )}
                />

                <Route
                  path="/make-payment/:base64"
                  element={withErrorBoundary(
                    <RouteMustBeLoggedIn>
                      <MakeStripePayment />
                    </RouteMustBeLoggedIn>
                  )}
                />
                <Route
                  path="/payment-successful"
                  element={
                    <RouteMustBeLoggedIn>
                      <PaymentSuccessful />
                    </RouteMustBeLoggedIn>
                  }
                />
                <Route
                  path="/payments"
                  element={withErrorBoundary(
                    <RouteMustBeLoggedIn>
                      <Payments />
                    </RouteMustBeLoggedIn>
                  )}
                />

                <Route
                  path="/templates/change/:id"
                  element={withErrorBoundary(<ModifyTemplate />)}
                />
                <Route
                  path="/templates/view/:id"
                  element={withErrorBoundary(<ViewTemplate />)}
                />
                <Route
                  path="/templates/create_invitations/:id"
                  element={withErrorBoundary(
                    <RouteMustBeLoggedIn>
                      <CreateInvitations />
                    </RouteMustBeLoggedIn>
                  )}
                />
                <Route
                  path="/guarantee"
                  element={withErrorBoundary(
                    <>
                      <SpecialOffer />
                      <Guarantee />
                    </>
                  )}
                />
                <Route
                  path="/"
                  element={withErrorBoundary(
                    <>
                      <SpecialOffer />
                      <CreateTemplate />
                    </>
                  )}
                />
                <Route
                  path="*"
                  element={withErrorBoundary(
                    <>
                      <SpecialOffer />
                      <Page404 />
                    </>
                  )}
                />
              </Routes>
            </RPS.ProSidebarProvider>
          </BrowserRouter>
        </BalanceContext.Provider>
      </AuthContext.Provider>
    </div>
  );
}

export default App;
