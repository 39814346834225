export const sentencify = (items: string[]) => {
  if (items.length === 0) return "";
  if (items.length === 1) return items[0];
  if (items.length === 2) return items.join(" and ");
  return `${items.slice(0, -1).join(", ")}, and ${items[items.length - 1]}`;
};

export const capitalize = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

export const parseDjangoDate = (date: string) => {
  // date is in format "YYYY-MM-DDT00:00:00.000Z"
  // we want to return "YYYY-MM-DD HH:MM AM/PM" in the user's timezone

  // Parse the input date string
  const dateObject = new Date(date);

  // Get the user's timezone offset
  const offset = dateObject.getTimezoneOffset();

  // Adjust the date object to the user's timezone
  dateObject.setMinutes(dateObject.getMinutes() - offset);

  // Format the date as "YYYY-MM-DD HH:MM AM/PM"
  const formattedDate = dateObject.toLocaleString("en-US", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  });

  return formattedDate;
};
