import React from "react";
import { RsvpQuestion, Template } from "../../types/template";
import { EinvitationIndividual } from "../../types/einvitation";
import {
  Alert,
  Button,
  Col,
  Container,
  Form,
  ProgressBar,
  Row,
} from "react-bootstrap";
import { animated, useSpring } from "@react-spring/web";

const questionIsAnswered = (
  question: RsvpQuestion,
  einv: EinvitationIndividual
) => {
  return (
    question.question in einv.rsvp_answers &&
    einv.rsvp_answers[question.question] !== null &&
    einv.rsvp_answers[question.question] !== ""
  );
};

const getFirstUnansweredQuestionIndex = (
  template: Template,
  einvitation: EinvitationIndividual
) => {
  for (let i = 0; i < template.rsvp_questions.length; i++) {
    if (!questionIsAnswered(template.rsvp_questions[i], einvitation)) {
      return i;
    }
  }
  return template.rsvp_questions.length;
};

const questionSpringConfig = {
  from: { scale: 0, opacity: 0 },
  to: { scale: 1, opacity: 1 },
  config: {
    duration: 300,
    tension: 10,
    friction: 10,
  },
};

function Question({
  question,
  einvitation,
  setEinvitation,
}: {
  question: RsvpQuestion;
  einvitation: EinvitationIndividual;
  setEinvitation: React.Dispatch<
    React.SetStateAction<EinvitationIndividual | null>
  >;
}) {
  const [spring, api] = useSpring(() => questionSpringConfig);

  // reanimate if the question changes between renders
  React.useEffect(() => {
    api.start(questionSpringConfig);
  }, [question.question, api]);

  return (
    <animated.div
      style={{
        ...spring,
      }}
    >
      <Form.Group controlId={question.id}>
        <Form.Label>{question.question}</Form.Label>
        {question.options.length > 0 ? (
          <div className="space-children-2">
            {question.options.map((option) => {
              return (
                <Form.Check
                  id={option}
                  key={option}
                  type="radio"
                  label={option}
                  checked={
                    einvitation.rsvp_answers[question.question] === option
                  }
                  onChange={(e) => {
                    if (e.target.checked) {
                      setEinvitation({
                        ...einvitation,
                        rsvp_answers: {
                          ...einvitation.rsvp_answers,
                          [question.question]: option,
                        },
                      });
                    }
                  }}
                />
              );
            })}
          </div>
        ) : (
          <Form.Control
            type="text"
            value={einvitation.rsvp_answers[question.question] || ""}
            onChange={(e) => {
              setEinvitation({
                ...einvitation,
                rsvp_answers: {
                  ...einvitation.rsvp_answers,
                  [question.question]: e.target.value,
                },
              });
            }}
            isValid={questionIsAnswered(question, einvitation)}
          />
        )}
      </Form.Group>
    </animated.div>
  );
}

function RsvpForm({
  template,
  einvitation,
  setEinvitation,
}: {
  template: Template;
  einvitation: EinvitationIndividual;
  setEinvitation: React.Dispatch<
    React.SetStateAction<EinvitationIndividual | null>
  >;
}) {
  const numberAnsweredQuestions = template.rsvp_questions.filter((q) =>
    questionIsAnswered(q, einvitation)
  ).length;
  const percentAnsweredQuestions =
    Math.round(
      (numberAnsweredQuestions / template.rsvp_questions.length) * 100
    ) || 1;

  const [currentQuestionIndex, setCurrentQuestionIndex] =
    React.useState<number>(() =>
      getFirstUnansweredQuestionIndex(template, einvitation)
    );
  const currentQuestion = template.rsvp_questions[currentQuestionIndex];
  return (
    <div className="w-100 h-100 text-start position-relative">
      <Container className="align-items-center">
        <Row className="position-relative pt-4 top-0 justify-content-center">
          <Col xs={12} sm={11} md={10} lg={9} xl={8}>
            <ProgressBar
              variant="success"
              now={percentAnsweredQuestions}
              animated
              label={`${percentAnsweredQuestions}%`}
              visuallyHidden
            />
          </Col>
        </Row>
        <Row className="h-100 w-100 position-absolute top-0 align-items-center justify-content-center">
          <Col xs={12} sm={11} md={10} lg={9} xl={8}>
            <Row>
              <Col>
                {currentQuestionIndex === template.rsvp_questions.length ? (
                  percentAnsweredQuestions === 100 ? (
                    <Alert variant="success" className="mb-0">
                      <p>Done! Your answers were automatically submitted</p>
                      <p className="mb-0">
                        Feel free to change your previous answers
                      </p>
                    </Alert>
                  ) : (
                    <Alert variant="warning" className="mb-0">
                      <p>
                        Only {percentAnsweredQuestions}% of questions answered
                      </p>
                      <p className="mb-0">
                        Please go back and complete all the questions
                      </p>
                    </Alert>
                  )
                ) : (
                  <Question
                    question={currentQuestion}
                    einvitation={einvitation}
                    setEinvitation={setEinvitation}
                  />
                )}
              </Col>
            </Row>
            <Row className="mt-3">
              <Col>
                {currentQuestionIndex !== 0 && (
                  <Button
                    className="w-100"
                    variant="secondary"
                    onClick={() => {
                      setCurrentQuestionIndex(currentQuestionIndex - 1);
                    }}
                  >
                    Previous
                  </Button>
                )}
              </Col>
              <Col>
                {currentQuestionIndex !== template.rsvp_questions.length && (
                  <Button
                    className="w-100"
                    variant="primary"
                    onClick={() => {
                      setCurrentQuestionIndex(currentQuestionIndex + 1);
                    }}
                  >
                    Next
                  </Button>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default RsvpForm;
