import React from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";

import { ReactComponent as Logo } from "../../icons/JustWeddingInvites.svg";
import { PROFILE_PAGE, WEB_NAME } from "../../constants";
import AuthContext from "../../contexts/AuthContext";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import BalanceBadge from "../payments/BalanceBadge";

function Header() {
  const { user } = React.useContext(AuthContext);

  const hiddenInMobileId = "basic-navbar-nav";
  return (
    <Navbar bg="light" expand="lg" className="border-bottom">
      <Container>
        <Link to="/" className="navbar-brand">
          <Logo className="me-2" height="1.7rem" width="1.7rem" />
          {WEB_NAME}
        </Link>
        <>
          <Navbar.Toggle aria-controls={hiddenInMobileId} />
          <Navbar.Collapse
            className="justify-content-end"
            id={hiddenInMobileId}
          >
            <Nav>
              {user.email !== "" ? (
                <>
                  <Link to={PROFILE_PAGE} className="nav-link">
                    {user.email}
                  </Link>
                  <BalanceBadge className="my-2 d-flex align-items-center" />
                </>
              ) : (
                <Link to="/sign-up">
                  <Button>Sign up</Button>
                </Link>
              )}
            </Nav>
          </Navbar.Collapse>
        </>
      </Container>
    </Navbar>
  );
}

export default Header;
