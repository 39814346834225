import React from "react";
import { Button } from "react-bootstrap";
import { DEFAULT_CONFIG, FILE_UPLOAD_CONFIG } from "../../constants";
import axios, { AxiosResponse } from "axios";
import { v4 as uuid } from "uuid";

import "./FileUpload.scss";
import { toast } from "react-toastify";

type Fields = {
  [key: string]: string;
};

type UploadParams = { url: string; fields: Fields; obj_key: string };

function FileUploadButton({
  addToTemplate,
  ...props
}: {
  addToTemplate: (mimeType: string, key: string, url: string) => void;
  [key: string]: any;
}) {
  const inputRef = React.useRef<HTMLInputElement>(null);

  const handleFileSelected = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    // check if file is selected
    if (!event.target.files || event.target.files.length === 0) {
      toast.error("No file selected");
      return;
    }
    if (event.target.files.length > 1) {
      toast.error("Please select only one file");
      return;
    }
    const file = event.target.files[0];

    // get upload params
    let resUploadParams: AxiosResponse<UploadParams>;
    try {
      resUploadParams = await axios.get(
        `/common/s3_url/upload/?objectName=${file.name}`,
        DEFAULT_CONFIG
      );
    } catch (err) {
      toast.error("Error getting upload URL");
      return;
    }
    // rename file so as to not confuse s3
    const renamedFile = new File([file], resUploadParams.data.obj_key);

    // put fields in formData. Order matters - you could get a 403.
    const formData = new FormData();
    for (const [key, value] of Object.entries(resUploadParams.data.fields)) {
      formData.append(key, value);
    }
    formData.append("file", renamedFile);

    // upload file
    try {
      await axios.post(resUploadParams.data.url, formData, FILE_UPLOAD_CONFIG);
    } catch (err) {
      toast.error("Error uploading file");
    }

    // get file url
    // TODO: if slow, do this while uploading it?
    let resFileUrl: AxiosResponse<{ url: string }>;
    try {
      resFileUrl = await axios.get(
        `/common/s3_url/fetch/?objectName=${resUploadParams.data.obj_key}`,
        DEFAULT_CONFIG
      );
      toast.success(`File uploaded successfully`);
    } catch (err) {
      toast.error("Error getting file URL");
      return;
    }

    // add file key and url to template contents
    // note that file.type is not reliable as it can be spoofed
    addToTemplate(
      file.type,
      resUploadParams.data.fields.key,
      resFileUrl.data.url
    );
  };

  return (
    <div className="file-upload" {...props}>
      <Button
        onClick={() => inputRef.current?.click()}
        variant="secondary"
        className="file-upload__button"
      >
        Add image or video
      </Button>
      <input
        ref={inputRef}
        type="file"
        className="file-upload__input"
        onChange={handleFileSelected}
      />
    </div>
  );
}

export default FileUploadButton;
