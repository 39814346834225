import React from "react";

import Button from "react-bootstrap/Button";
import { Icon } from "../SlateComponents";
import ConfirmModal from "../modal/ConfirmModal";

function DeleteButton({
  confirmDelete,
  ...props
}: {
  confirmDelete: () => void;
  [key: string]: any;
}) {
  const [modalShow, setModalShow] = React.useState(false);
  return (
    <>
      <Button
        variant="outline-danger"
        size="sm"
        style={{ position: "absolute", top: 0, right: 0, zIndex: 1000 }}
        onClick={() => setModalShow(true)}
      >
        <Icon>clear</Icon>
      </Button>
      {modalShow && (
        <ConfirmModal
          onHide={() => setModalShow(false)}
          confirm={confirmDelete}
        />
      )}
    </>
  );
}

export default DeleteButton;
