import React, { useMemo } from "react";
import { Slate, Editable, withReact, ReactEditor } from "slate-react";
import { createEditor } from "slate";

import { withHistory } from "slate-history";
import { toggleFormat } from "../text/utils";
import TextToolbar from "../text/TextToolbar";
import Leaf from "../text/Leaf";
import { ElementText, SlateElementText } from "../../types/content";
import { Size } from "../../types/basic";

function useFontSize(
  text: string,
  targetSize: Size,
  containerRef: React.RefObject<HTMLSpanElement>
) {
  const [fontSize, setFontSize] = React.useState(10);
  const [visitedFontSizes, setVisitedFontSizes] = React.useState<number[]>([]);

  // Adjust size until text box reaches size
  React.useEffect(() => {
    if (!containerRef.current || text.length === 0) {
      return;
    }
    // get size of the div
    const rect = containerRef.current.getBoundingClientRect();
    if (rect.width === 0 || rect.height === 0) {
      return;
    }
    let nextFontSize: number;
    if (rect.height < targetSize.height) {
      nextFontSize = fontSize + 1;
    } else {
      nextFontSize = fontSize - 1;
    }
    if (visitedFontSizes.includes(nextFontSize)) {
      return;
    }
    setVisitedFontSizes([...visitedFontSizes, nextFontSize]);
    setFontSize(nextFontSize);
  }, [
    targetSize.width,
    targetSize.height,
    fontSize,
    visitedFontSizes,
    containerRef,
    text.length,
  ]);

  // Reset visited font sizes when size changes
  React.useEffect(() => {
    setVisitedFontSizes([]);
  }, [targetSize.width, targetSize.height]);

  return fontSize;
}

function getTextFromTextEl(element: ElementText) {
  return element.slateElements
    .map((v) => v.children.map((v) => v.text).join(""))
    .join("");
}

export function ViewText({
  size,
  element,
}: {
  size: Size;
  element: ElementText;
}) {
  const editor = useMemo(() => withHistory(withReact(createEditor())), []);
  const ref = React.useRef<HTMLSpanElement>(null);
  const fontSize = useFontSize(getTextFromTextEl(element), size, ref);

  return (
    <span
      style={{ fontSize: `${fontSize}px`, color: element.color }}
      ref={ref}
      className={`text-${element.textAlign}`}
    >
      <Slate editor={editor} value={element.slateElements}>
        <Editable readOnly renderLeaf={(props) => <Leaf {...props} />} />
      </Slate>
    </span>
  );
}

export function EditText({
  size,
  element,
  setElement,
}: {
  size: Size;
  element: ElementText;
  setElement: (element: ElementText) => void;
}) {
  const editor = useMemo(() => withHistory(withReact(createEditor())), []);
  const ref = React.useRef<HTMLSpanElement>(null);
  const fontSize = useFontSize(getTextFromTextEl(element), size, ref);

  // Focus if touched
  React.useEffect(() => {
    if (!ref.current) {
      return;
    }
    const el = ref.current;
    const focus = () => {
      ReactEditor.focus(editor);
    };
    el.addEventListener("touchstart", focus);
    return () => {
      el.removeEventListener("touchstart", focus);
    };
  }, [editor]);

  return (
    <span
      style={{ fontSize: `${fontSize}px`, color: element.color }}
      ref={ref}
      className={`text-${element.textAlign}`}
    >
      <Slate
        editor={editor}
        value={element.slateElements}
        onChange={(value) => {
          setElement({
            ...element,
            slateElements: value as SlateElementText[],
          });
        }}
      >
        <TextToolbar element={element} setElement={setElement} />
        <Editable
          renderLeaf={(props) => <Leaf {...props} />}
          onDOMBeforeInput={(event: InputEvent) => {
            switch (event.inputType) {
              case "formatBold":
                event.preventDefault();
                return toggleFormat(editor, "bold");
              case "formatItalic":
                event.preventDefault();
                return toggleFormat(editor, "italic");
              case "formatUnderline":
                event.preventDefault();
                return toggleFormat(editor, "underlined");
            }
          }}
        />
      </Slate>
    </span>
  );
}

export default EditText;
