import React, { useRef, useEffect } from "react";
import { useSlate, useFocused } from "slate-react";
import { Editor, Range } from "slate";
import { css } from "@emotion/css";
import { CompactPicker } from "react-color";

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";

import { Portal, Menu, Button, Icon } from "../SlateComponents";
import { isFormatActive, setFormat, toggleFormat } from "./utils";
import { MyDescendant } from "../../types/slate";
import Dropdown from "react-bootstrap/Dropdown";
import { ElementText, TextAlign } from "../../types/content";

const FormatButton = ({ format, icon }: { format: string; icon: string }) => {
  const editor = useSlate();
  return (
    <Button
      reversed
      active={isFormatActive(editor, format)}
      onClick={() => toggleFormat(editor, format)}
    >
      <Icon>{icon}</Icon>
    </Button>
  );
};

const AlignButton = ({
  element,
  setElement,
  format,
  icon,
}: {
  element: ElementText;
  setElement: (el: ElementText) => void;
  format: TextAlign;
  icon: string;
}) => {
  return (
    <Button
      reversed
      active={element.textAlign === format}
      onClick={() => {
        setElement({ ...element, textAlign: format });
      }}
    >
      <Icon>{icon}</Icon>
    </Button>
  );
};

const ColorButton = ({
  element,
  setElement,
  icon,
}: {
  element: ElementText;
  setElement: (el: ElementText) => void;
  icon: string;
}) => {
  return (
    <OverlayTrigger
      trigger="click"
      placement="right"
      overlay={
        <Popover id="popover-basic" className="max-z">
          <Popover.Header as="h3">Color</Popover.Header>
          <Popover.Body>
            <CompactPicker
              color={element.color}
              onChange={(color) => {
                setElement({ ...element, color: color.hex });
              }}
            />
          </Popover.Body>
        </Popover>
      }
    >
      <Button reversed>
        <Icon
          style={{
            color: element.color,
            backgroundColor: "#E2E2E2",
            padding: "0.05rem",
            borderRadius: "0.1rem",
          }}
        >
          {icon}
        </Icon>
      </Button>
    </OverlayTrigger>
  );
};

const FONT_URL_OPTIONS = [
  "https://fonts.googleapis.com/css2?family=Cabin",
  "https://fonts.googleapis.com/css2?family=Mate",
  "https://fonts.googleapis.com/css2?family=Tangerine",
  "https://fonts.googleapis.com/css2?family=Corinthia",
];

const FormatDropdown = () => {
  const editor = useSlate();
  // determine the font_url of the current Slate Editor selection
  if (!editor.selection) return null;
  const node: MyDescendant = Editor.node(editor, editor.selection)[0];
  let font_url = node.font_url;
  if (!font_url && node.children) {
    font_url = node.children.find((child) => child.font_url)?.font_url;
  }
  if (!font_url) return null;

  return (
    <Dropdown>
      <Dropdown.Toggle variant="secondary">
        {font_url.split("family=")[1]}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {FONT_URL_OPTIONS.map((fontUrl) => (
          <Dropdown.Item
            key={fontUrl}
            active={fontUrl === font_url}
            onClick={() => {
              setFormat(editor, "font_url", fontUrl);
            }}
          >
            <link rel="stylesheet" href={fontUrl} />
            <span style={{ fontFamily: fontUrl.split("family=")[1] }}>
              {fontUrl.split("family=")[1]}
            </span>
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

const TextToolbar = ({
  element,
  setElement,
}: {
  element: ElementText;
  setElement: (element: ElementText) => void;
}) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const editor = useSlate();
  const inFocus = useFocused();

  useEffect(() => {
    const el = ref.current;
    const { selection } = editor;

    if (!el) {
      return;
    }

    if (
      !selection ||
      !inFocus ||
      Range.isCollapsed(selection) ||
      Editor.string(editor, selection) === ""
    ) {
      el.removeAttribute("style");
      return;
    }

    const domSelection = window.getSelection();
    const domRange = domSelection?.getRangeAt(0);
    const rect = domRange?.getBoundingClientRect();
    if (!rect) {
      return;
    }
    el.style.opacity = "1";
    el.style.top = `${rect.top + window.pageYOffset - el.offsetHeight}px`;
    el.style.left = `${
      rect.left + window.pageXOffset - el.offsetWidth / 2 + rect.width / 2
    }px`;
  });

  return (
    <Portal>
      <Menu
        ref={ref}
        className={
          "max-z " +
          css`
            padding: 8px 7px 6px;
            position: absolute;
            top: -10000px;
            left: -10000px;
            margin-top: -6px;
            opacity: 0;
            background-color: #222;
            border-radius: 4px;
            transition: opacity 0.75s;
          `
        }
        onMouseDown={(e: React.MouseEvent) => {
          // prevent toolbar from taking focus away from editor
          e.preventDefault();
        }}
      >
        <FormatButton format="bold" icon="format_bold" />
        <FormatButton format="italic" icon="format_italic" />
        <FormatButton format="underlined" icon="format_underlined" />
        <AlignButton
          element={element}
          setElement={setElement}
          format="start"
          icon="format_align_left"
        />
        <AlignButton
          element={element}
          setElement={setElement}
          format="center"
          icon="format_align_center"
        />
        <AlignButton
          element={element}
          setElement={setElement}
          format="end"
          icon="format_align_right"
        />
        <ColorButton
          element={element}
          setElement={setElement}
          icon="format_color_text"
        />

        <FormatDropdown />
      </Menu>
    </Portal>
  );
};

export default TextToolbar;
