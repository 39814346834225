import { useParams } from "react-router-dom";
import StripeWrapper from "../components/stripe/StripeWrapper";
import { base64ToNum } from "../utils/numbers";
import Page404 from "../page_fallbacks/Page404";
import { Col, Container, Row } from "react-bootstrap";

function MakeStripePayment() {
  const { base64: amount_in_cents_base64 } = useParams<{ base64: string }>();
  if (!amount_in_cents_base64) return <Page404 />;

  const amount_in_cents = base64ToNum(amount_in_cents_base64);
  if (!amount_in_cents) return <Page404 />;

  return (
    <Container>
      <h1 className="text-center my-5">
        Add ${amount_in_cents / 100} USD credit
      </h1>
      <Row>
        <Col xs={12}>
          <StripeWrapper amount_in_cents={amount_in_cents} />;
        </Col>
      </Row>
    </Container>
  );
}

export default MakeStripePayment;
