export const numToBase64 = (num: number) => {
  return btoa(num.toString());
};

export const base64ToNum = (base64: string) => {
  try {
    return parseInt(atob(base64));
  } catch (err) {
    return null;
  }
};
