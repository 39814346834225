import React from "react";
import axios, { AxiosResponse } from "axios";
import { toast } from "react-toastify";
import { DEFAULT_CONFIG } from "../constants";
import AuthContext from "../contexts/AuthContext";
import { Template } from "../types/template";

function useSetOwnerIfNone(
  template: Template | null,
  setTemplate: React.Dispatch<React.SetStateAction<Template | null>>
) {
  const { user } = React.useContext(AuthContext);
  React.useEffect(() => {
    const trySetSelfAsOwner = async () => {
      if (template && template.creator_team_id == null && user) {
        try {
          const res: AxiosResponse<Template> = await axios.patch(
            `/templates/${template.id}/`,
            {},
            DEFAULT_CONFIG
          );
          setTemplate(res.data);
        } catch (error) {
          toast.error("Error setting team as owner");
        }
      }
    };
    trySetSelfAsOwner();
  }, [template, setTemplate, user]);
}

export default useSetOwnerIfNone;
