import React from "react";
import { MyDescendant } from "../../types/slate";

const Leaf = ({
  attributes,
  children,
  leaf,
}: {
  attributes: any;
  children: JSX.Element;
  leaf: MyDescendant;
}) => {
  if (leaf.bold) {
    children = <strong>{children}</strong>;
  }

  if (leaf.italic) {
    children = <em>{children}</em>;
  }

  if (leaf.underlined) {
    children = <u>{children}</u>;
  }
  if (leaf.font_url) {
    children = (
      <span>
        <link rel="stylesheet" href={leaf.font_url} />
        <span style={{ fontFamily: leaf.font_url.split("family=")[1] }}>
          {children}
        </span>
      </span>
    );
  }

  return <span {...attributes}>{children}</span>;
};

export default Leaf;
