import React from "react";

export type BalanceContextType = {
  balance: number;
  setBalance:
    | React.Dispatch<React.SetStateAction<number>>
    | ((u: number) => void);
};

const BalanceContext = React.createContext<BalanceContextType>({
  balance: 0,
  setBalance: (b: number) => {
    throw new Error("No setUser function provided");
  },
});

export default BalanceContext;
