import React from "react";
import { Transaction } from "../types/payments";
import axios, { AxiosResponse } from "axios";
import { DEFAULT_CONFIG } from "../constants";
import { toast } from "react-toastify";
import { Badge, Card, Col, Container, ListGroup, Row } from "react-bootstrap";
import { capitalize, parseDjangoDate } from "../utils/text";
import { backendTransactionToFrontend } from "../utils/transactions";
import AuthContext from "../contexts/AuthContext";
import AddCredits from "../components/stripe/AddCredits";
import ProfileSidebar from "../components/sidebars/ProfileSideBar";

function Payments() {
  const { user } = React.useContext(AuthContext);
  const [transactions, setTransactions] = React.useState<Transaction[]>([]);

  // fetch transactions
  React.useEffect(() => {
    const fetchTransactions = async () => {
      try {
        const res: AxiosResponse<{ transactions: Transaction[] }> =
          await axios.get("/payments/transactions/", DEFAULT_CONFIG);
        setTransactions(res.data.transactions);
      } catch (e: any) {
        toast.error("Error fetching transactions");
      }
    };
    fetchTransactions();
  }, []);

  return (
    <div className="d-flex">
      <ProfileSidebar />
      <Container className="pt-5">
        <h1 style={{ display: "none" }}>Credits</h1>
        <Row className="d-flex justify-content-center gy-5">
          <Col xs={12} lg={6}>
            <h2 className="mb-4">Add Credits</h2>
            <AddCredits />
          </Col>
          <Col xs={12} lg={6}>
            <h2 className="mb-4">Transactions</h2>
            <Card>
              <Card.Body>
                {transactions.length === 0 && (
                  <p className="text-muted">No transactions yet</p>
                )}
                <ListGroup>
                  {transactions.map((transaction) => (
                    <ListGroup.Item key={transaction.id}>
                      <h3 className="h5 d-flex">
                        {capitalize(
                          backendTransactionToFrontend[transaction.type]
                        )}
                        <Badge
                          className="ms-auto"
                          bg={transaction.amount > 0 ? "success" : "secondary"}
                        >
                          ${transaction.amount / 100}
                        </Badge>
                      </h3>
                      <em className="text-muted">
                        {parseDjangoDate(transaction.created_at)}
                      </em>
                      <p className="pt-2 pb-0">
                        By:{" "}
                        {transaction.initiater_id === user?.id
                          ? "you"
                          : `another user on your team`}
                      </p>
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Payments;
