import React from "react";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

function ConfirmModal({
  onHide,
  confirm,
  title = "Delete content",
  confirmDescription = "delete this content",
  variant = "danger",
  confirmText = "Delete",
  optionalDetails = null,
  ...props
}: {
  onHide: () => void;
  confirm: () => void;
  title?: string;
  confirmDescription?: string;
  variant?: "danger" | "primary";
  confirmText?: string;
  optionalDetails?: React.ReactNode;
  [key: string]: any;
}) {
  return (
    <Modal
      {...props}
      className="max-z"
      show={true}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      onHide={onHide}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="space-children-4">
        <p>Are you sure you want to {confirmDescription}?</p>
        {optionalDetails}
        <p>
          <strong>This action cannot be undone.</strong>
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Nevermind
        </Button>
        <Button variant={variant} onClick={confirm}>
          {confirmText}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ConfirmModal;
