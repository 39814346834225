import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import axios from "axios";
import posthog from "posthog-js";
import LogRocket from "logrocket";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

if (process.env.REACT_APP_BUILD_ENV === "production") {
  LogRocket.init("just-wedding-invites/just-wedding-invites");
  posthog.init("phc_5HwIcPyTgZrc96LZ8fOVHRnzSmFw55XyKRV8srsZ7HX", {
    api_host: "https://app.posthog.com",
  });
  axios.defaults.baseURL = "https://api.justweddinginvites.com";
  console.log("Welcome!");
} else {
  console.log("Development mode");
  axios.defaults.baseURL = "http://localhost:8000";
}

root.render(
  //   <React.StrictMode>
  <App />
  //   </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
