import { useEffect, useState } from "react";
import useWindowWidth from "./useWindowWidth";

function useElWidth(element: React.RefObject<HTMLDivElement>) {
  const [elWidth, setElWidth] = useState(0);
  const [effectTrigger, setEffectTrigger] = useState(0);
  const windowWidth = useWindowWidth();

  useEffect(() => {
    if (!element.current) {
      setEffectTrigger((prev) => prev + 1);
      return;
    }
    setElWidth(element.current.clientWidth);
  }, [element, effectTrigger, windowWidth]);

  return elWidth;
}

export default useElWidth;
