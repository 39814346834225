import React from "react";
import { DEFAULT_CONFIG } from "../../constants";
import axios from "axios";
import { toast } from "react-toastify";
import { Alert, Button, Card, Form } from "react-bootstrap";
function InviteToTeam() {
  const [email, setEmail] = React.useState("");
  const [awaitingInvite, setAwaitingInvite] = React.useState(false);
  const [inviteSuccess, setInviteSuccess] = React.useState(false);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setAwaitingInvite(true);

    try {
      await axios.post(
        "/identities/team/invite/",
        { target_user_email: email },
        DEFAULT_CONFIG
      );
      setInviteSuccess(true);
    } catch (e: any) {
      toast.error("Error inviting user");
    }
    setAwaitingInvite(false);
  };

  return (
    <Card>
      <Card.Body>
        <Card.Title>Invite to team</Card.Title>
        <Form onSubmit={handleSubmit} className="space-children-4 mt-4">
          <Form.Group controlId="formEmail">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="text"
              value={email}
              onChange={(event) => setEmail(event.target.value)}
            />
          </Form.Group>

          {inviteSuccess ? (
            <>
              <Alert variant="success">Invitation successful</Alert>
              <div className="d-grid gap-2">
                <Button
                  variant="secondary"
                  type="button"
                  onClick={() => {
                    setInviteSuccess(false);
                    setEmail("");
                  }}
                >
                  Invite more
                </Button>
              </div>
            </>
          ) : (
            <div className="d-grid gap-2">
              <Button variant="primary" type="submit" disabled={awaitingInvite}>
                {awaitingInvite ? "Inviting..." : "Invite"}
              </Button>
            </div>
          )}
        </Form>
      </Card.Body>
    </Card>
  );
}

export default InviteToTeam;
