import React from "react";

const FallbackUI = ({ error }: { error: Error }) => {
  // TODO: add a better UI and contact page
  return (
    <div>
      <h1>Oops, something went wrong</h1>
      <p>{error.message}</p>

      <p>
        This app is in beta. Please try refreshing the page or check back next
        week!
      </p>
      <button onClick={() => window.location.reload()}>Refresh</button>
    </div>
  );
};

interface Props {
  children: React.ReactNode;
}

interface State {
  hasError: boolean;
  error: Error | null;
}

class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    console.error(error, errorInfo);
  }

  render() {
    if (this.state.hasError && this.state.error != null) {
      return <FallbackUI error={this.state.error} />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
