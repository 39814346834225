import React from "react";
import { Button, Card, CloseButton, Form, Modal } from "react-bootstrap";
import { RsvpQuestion, Template } from "../../types/template";
import { toast } from "react-toastify";
import { DEFAULT_CONFIG } from "../../constants";
import axios from "axios";
import { v4 as uuid } from "uuid";

function Question({
  question,
  setQuestion,
  removeQuestion,
  index,
}: {
  question: RsvpQuestion;
  setQuestion: (question: RsvpQuestion) => void;
  removeQuestion: (question: RsvpQuestion) => void;
  index: number;
}) {
  const [useOptions, setUseOptions] = React.useState(
    question.options.length > 0
  );
  return (
    <Card>
      <Card.Header className="d-flex align-items-center">
        <span>Question {index + 1}</span>
        <Form.Check
          className="ms-3"
          type="switch"
          id={`switch-${question.id}`}
          label="Allow options"
          onChange={(e) => {
            if (!e.target.checked) {
              setQuestion({ ...question, options: [] });
            } else {
              setQuestion({ ...question, options: [""] });
            }
            setUseOptions(e.target.checked);
          }}
          checked={useOptions}
        />
        <CloseButton
          className="ms-auto"
          onClick={() => {
            removeQuestion(question);
          }}
        />
      </Card.Header>
      <Card.Body>
        <Form.Group controlId={question.id} className="mb-2">
          <div className="d-flex align-items-center">
            <Form.Label className="me-4">Question:</Form.Label>
            <Form.Control
              type="text"
              value={question.question}
              onChange={(e) => {
                setQuestion({ ...question, question: e.target.value });
              }}
            />
          </div>
        </Form.Group>
        {useOptions && (
          <div className="mt-5">
            <div className="mb-2">Options:</div>
            <div className="space-children-2">
              {question.options.map((option, i) => (
                <Form.Group key={i} controlId={`option-${i}`}>
                  <Form.Label visuallyHidden={true}>
                    Option {index + 1}
                  </Form.Label>
                  <div className="d-flex align-items-center">
                    <Form.Control
                      key={i}
                      type="text"
                      value={option}
                      onChange={(e) => {
                        setQuestion({
                          ...question,
                          options: question.options.map((o, j) =>
                            i === j ? e.target.value : o
                          ),
                        });
                      }}
                    />
                    <CloseButton
                      className="ms-2"
                      onClick={() => {
                        setQuestion({
                          ...question,
                          options: question.options.filter((o, j) => i !== j),
                        });
                      }}
                    />
                  </div>
                </Form.Group>
              ))}
              <div className="d-flex">
                <Button
                  className="ms-auto"
                  onClick={() => {
                    setQuestion({
                      ...question,
                      options: [...question.options, ""],
                    });
                  }}
                >
                  Add option
                </Button>
              </div>
            </div>
          </div>
        )}
      </Card.Body>
    </Card>
  );
}

function InvitationsSettings({
  show,
  setShow,
  template,
  setTemplate,
}: {
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  template: Template | null;
  setTemplate: React.Dispatch<React.SetStateAction<Template | null>>;
}) {
  const addRsvpQuestion = async () => {
    if (template === null) return;
    const newQuestion = {
      id: uuid(),
      question: "",
      options: [],
    };
    try {
      await axios.patch(
        `/templates/${template.id}/`,
        {
          rsvp_questions: [...template.rsvp_questions, newQuestion],
        },
        DEFAULT_CONFIG
      );
      setTemplate({
        ...template,
        rsvp_questions: [...template.rsvp_questions, newQuestion],
      });
    } catch (e: any) {
      toast.error("Failed to add RSVP question");
    }
  };

  const setQuestion = async (question: RsvpQuestion) => {
    if (template === null) return;
    setTemplate({
      ...template,
      rsvp_questions: template.rsvp_questions.map((q) =>
        q.id === question.id ? question : q
      ),
    });
  };

  const removeQuestion = async (question: RsvpQuestion) => {
    if (template === null) return;
    setTemplate({
      ...template,
      rsvp_questions: template.rsvp_questions.filter(
        (q) => q.id !== question.id
      ),
    });
  };

  return (
    <Modal
      className="max-z"
      show={show}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      onHide={() => {
        setShow(false);
      }}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          RSVP questions
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="space-children-4">
        {template &&
          template.rsvp_questions.map((q, i) => (
            <Question
              key={q.id}
              question={q}
              setQuestion={setQuestion}
              removeQuestion={removeQuestion}
              index={i}
            />
          ))}
        <Button onClick={addRsvpQuestion}>Add question</Button>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={() => {
            setShow(false);
          }}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default InvitationsSettings;
