import { Editor, Transforms, Text, BaseEditor } from "slate";

export const isFormatActive = (editor: BaseEditor, format: string) => {
  // @ts-ignore
  const [match] = Editor.nodes(editor, {
    match: (n: any) => n[format] === true,
    mode: "all",
  });
  return !!match;
};

export const toggleFormat = (editor: BaseEditor, format: string) => {
  const isActive = isFormatActive(editor, format);
  Transforms.setNodes(
    editor,
    { [format]: isActive ? null : true },
    { match: Text.isText, split: true }
  );
};

export const setFormat = (editor: BaseEditor, format: string, value: any) => {
  Transforms.setNodes(
    editor,
    { [format]: value },
    { match: Text.isText, split: true }
  );
};
