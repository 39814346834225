import React, { useCallback } from "react";
import { useParams } from "react-router-dom";
import axios, { AxiosResponse } from "axios";
import { v4 as uuidv4 } from "uuid";
import { Button, Col, Container, Row, Form } from "react-bootstrap";

import { Template } from "../types/template";

import { ElementText, Content } from "../types/content";
import { Size } from "../types/basic";
import { EditContent } from "../components/content/Content";
import EinvitationTemplateContainer from "../components/einvtemplateshared/EinvitationTemplateContainer";
import {
  DEFAULT_CONFIG,
  EDIT_TEMPLATE,
  GOLDEN_RATIO,
  INVITATION_PERCENT_WIDTH,
} from "../constants";
import { toast } from "react-toastify";
import FileUpload from "../components/files/FileUpload";
import useWindowWidth from "../hooks/useWindowWidth";
import { SketchPicker } from "react-color";
import * as RPS from "react-pro-sidebar";
import { Link } from "react-router-dom";
import { posthog } from "posthog-js";
import AuthContext from "../contexts/AuthContext";

function getMaxZposition(template: Template) {
  if (template.contents.length === 0) return 0;
  return Math.max(...template.contents.map((c: Content) => c.position.z));
}

function getNewElPosition(
  template: Template,
  e: React.MouseEvent<HTMLDivElement, MouseEvent>,
  containerRef: React.RefObject<HTMLDivElement>
) {
  if (!containerRef.current) {
    throw new Error("containerRef.current is null");
  }
  const maxZposition = getMaxZposition(template);
  const relativeToTopY =
    e.nativeEvent.y - containerRef.current.getBoundingClientRect().top!;
  const relativeToLeftX =
    e.nativeEvent.x - containerRef.current.getBoundingClientRect().left!;

  return { x: relativeToLeftX, y: relativeToTopY, z: maxZposition + 1 };
}

function ModifyTemplate() {
  const { id } = useParams<{ id: string }>();
  // use isEdited to prevent saving template on initial load
  const [isEdited, setIsEdited] = React.useState(false);
  // use ref to get template container
  const refTemplateContainer = React.useRef<HTMLDivElement>(null);
  // use template to store template data
  const [template, setTemplate] = React.useState<Template | null>(null);
  // use templateIsLandscape to determine if template is landscape or portrait
  const [templateIsLandscape, setTemplateIsLandscape] = React.useState<boolean>(
    window.innerWidth > window.innerHeight
  );
  // allow user to change template size based on landscape setting and their current window size
  const windowWidth = useWindowWidth();
  const templateId = template && template.id;
  React.useEffect(() => {
    const width = windowWidth * INVITATION_PERCENT_WIDTH;
    let height: number;
    if (templateIsLandscape) {
      height = width / GOLDEN_RATIO;
    } else {
      height = width * GOLDEN_RATIO;
    }
    setTemplate((prev) => {
      if (!prev) {
        return null;
      }
      const resizedAndPositionedContents = prev.contents.map((c) => {
        const newWidth = (width / prev.size.width) * c.size.width;
        const newHeight = (height / prev.size.height) * c.size.height;
        const newX = (width / prev.size.width) * c.position.x;
        const newY = (height / prev.size.height) * c.position.y;
        return {
          ...c,
          size: {
            width: newWidth,
            height: newHeight,
          },
          position: {
            x: newX,
            y: newY,
            z: c.position.z,
          },
        };
      });
      setIsEdited(true);

      return {
        ...prev,
        size: {
          width: width,
          height: height,
        },
        contents: resizedAndPositionedContents,
      };
    });
  }, [templateIsLandscape, windowWidth, templateId]);

  const { user } = React.useContext(AuthContext);

  React.useEffect(() => {
    if (templateIsLandscape && window.innerWidth < 700) {
      toast.warn(
        "We noticed that you're using a small screen to edit a landscape invitation. Please consider using a larger screen or portrait invitation.",
        { autoClose: false }
      );
    }
  }, [templateIsLandscape]);

  React.useEffect(() => {
    const fetchTemplate = async () => {
      const res: AxiosResponse<Template> = await axios.get(
        `/templates/${id}/`,
        DEFAULT_CONFIG
      );
      setTemplate(res.data);
    };
    fetchTemplate();
  }, [id]);

  const templateStr = JSON.stringify(template);
  React.useEffect(() => {
    const updateTemplate = async () => {
      if (!template || !isEdited) {
        return;
      }

      // do not save if positions or sizes are invalid
      const positions = template.contents.map((c) => c.position);
      const sizes = [template.size].concat(
        template.contents.map((c) => c.size)
      );
      if (sizes.some((s) => s.width == null || s.height == null)) {
        console.error(`Invalid size ${JSON.stringify(sizes)}`);
        return;
      }
      if (positions.some((p) => p.z == null || p.y == null || p.z == null)) {
        console.error(`Invalid position ${JSON.stringify(positions)}`);
        return;
      }

      try {
        await axios.put(`/templates/${template.id}/`, template, DEFAULT_CONFIG);

        // track id incase user loses template id/url
        const modifiedTemplateIds = JSON.parse(
          localStorage.getItem("modifiedTemplateIds") || JSON.stringify([])
        );
        if (!modifiedTemplateIds.includes(template.id)) {
          modifiedTemplateIds.push(template.id);
          localStorage.setItem(
            "modifiedTemplateIds",
            JSON.stringify(modifiedTemplateIds)
          );
        }

        toast.success("Saved", { autoClose: 1000 });
      } catch (err) {
        toast.error("Something went wrong. Please refresh the page.");
      }
    };

    // save template if no changes for 1 second
    const timeoutId = setTimeout(() => {
      updateTemplate();
    }, 1000);
    return () => clearTimeout(timeoutId);
  }, [template, templateStr, isEdited]);

  const createContent = (newContent: Content) => {
    if (!template || !newContent) {
      return;
    }

    const newTemplate = {
      ...template,
      contents: [...template.contents, newContent],
    };

    setTemplate(newTemplate);
    setIsEdited(true);
  };

  const generateText = () => {
    const size: Size = {
      width: 100,
      height: 100,
    };

    // create a text element
    const textEl: ElementText = {
      type: "TEXT" as const,
      textAlign: "center" as const,
      color: "#4D4D4D",
      slateElements: [
        {
          type: "TEXT" as const,
          children: [
            {
              text: "New Text",
              font_url: "https://fonts.googleapis.com/css2?family=Cabin",
              bold: false,
              italic: false,
              underlined: false,
            },
          ],
        },
      ],
    };

    return { size, textEl };
  };

  const createText = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    // if target is not the template container, do nothing
    if (e.target !== refTemplateContainer.current) {
      return;
    }
    // if data is invalid, raise error
    if (!template || !refTemplateContainer.current) {
      toast.error("Something went wrong. Please refresh the page.");
      return;
    }
    const uuid = uuidv4();
    const position = getNewElPosition(template, e, refTemplateContainer);
    const { size, textEl } = generateText();
    createContent({
      id: uuid,
      position: position,
      size: size,
      element: textEl,
    });
  };

  const createMedia = (mimeType: string, key: string, url: string) => {
    // if data is invalid, raise error
    if (!template || !refTemplateContainer.current) {
      toast.error("Something went wrong. Please refresh the page.");
      return;
    }
    const uuid = uuidv4();
    const maxZposition = getMaxZposition(template);
    const size = {
      width: 200,
      height: 200,
    };

    let el;
    if (mimeType.startsWith("image")) {
      el = {
        type: "IMAGE" as const,
        image: {
          key: key,
          url: url,
          alt: "",
        },
      };
    } else if (mimeType.startsWith("video")) {
      el = {
        type: "VIDEO" as const,
        video: {
          key: key,
          url: url,
        },
      };
    } else {
      throw new Error("Invalid media type");
    }

    createContent({
      id: uuid,
      position: {
        z: maxZposition + 1,
        x: 0,
        y: 0,
      },
      size: size,
      element: el,
    });
  };

  const setContent = useCallback(
    (content: Content) => {
      if (!template) {
        return;
      }
      content.position.z = getMaxZposition(template) + 1;
      const idx = template.contents.findIndex(
        (c: Content) => c.id === content.id
      );
      const newContents = [...template.contents];
      newContents[idx] = content;
      setTemplate({ ...template, contents: newContents });
      setIsEdited(true);
      posthog.capture(EDIT_TEMPLATE, { user: user.id });
    },
    [setTemplate, template, user.id]
  );
  const deleteContent = useCallback(
    (content: Content) => {
      // remove content from template
      if (!template) {
        return;
      }
      const newContents = template.contents.filter(
        (c: Content) => c.id !== content.id
      );
      setTemplate({ ...template, contents: newContents });
      setIsEdited(true);
    },
    [template]
  );

  if (!template) {
    return <div>No template</div>;
  }
  return (
    <div className="d-flex">
      <RPS.Sidebar>
        <h1 className="h5 pt-3 px-3">
          Modify Template: {template.name || "Template Name"}
        </h1>
        <div className="pt-3 d-flex justify-content-center">
          <Form.Group controlId="templateName">
            <Form.Label>Template Name</Form.Label>
            <Form.Control
              type="text"
              value={template.name || ""}
              onChange={(event) =>
                setTemplate({ ...template, name: event.target.value })
              }
            />
          </Form.Group>
        </div>
        <div className="pt-4 d-flex justify-content-center">
          <SketchPicker
            color={template.background_color}
            onChange={(color) => {
              setTemplate({ ...template, background_color: color.hex });
              setIsEdited(true);
            }}
          />
        </div>
      </RPS.Sidebar>
      <div className="pt-3 pb-5">
        <Link to={`/templates/view/${template.id}/`}>
          <Button
            variant="primary"
            className="fixed-button glisten scale-up-on-focus"
          >
            Preview
          </Button>
        </Link>

        <Container>
          <Row className="gy-3">
            <Col xs={12}>
              <FileUpload
                style={{ width: `${template.size.width}px` }}
                addToTemplate={createMedia}
              />
            </Col>
            <Col xs={12}>
              <EinvitationTemplateContainer
                isEditing={true}
                createText={createText}
                containerRef={refTemplateContainer}
                style={{
                  margin: "auto",
                  width: `${template.size.width}px`,
                  height: `${template.size.height}px`,
                  backgroundColor: template.background_color,
                }}
              >
                {template.contents.map((content: Content) => {
                  return (
                    <EditContent
                      key={content.id}
                      content={content}
                      setContent={setContent}
                      deleteContent={deleteContent}
                    />
                  );
                })}
              </EinvitationTemplateContainer>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}

export default ModifyTemplate;
