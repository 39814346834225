import React, { useMemo, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";

import {
  Button,
  Container,
  ListGroup,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import "react-data-grid/lib/styles.css";
import DataGrid, {
  SelectColumn,
  Column,
  SortColumn,
  textEditor,
} from "react-data-grid";
import axios, { AxiosResponse } from "axios";

import { Einvitation } from "../types/einvitation";
import { DEFAULT_CONFIG, SENT_INVITATIONS } from "../constants";
import CreateInvitationsGrid from "../components/einvitations/CreateInvitationsGrid";
import {
  selectCellClassname,
  sortStatus,
  checkboxFormatter,
} from "../components/tables/utils";
import { Template } from "../types/template";
import { toast } from "react-toastify";
import ConfirmModal from "../components/modal/ConfirmModal";
import { statusToIcon } from "../utils/icons";
import { Icon } from "../components/SlateComponents";
import { capitalize } from "../utils/text";
import useSetOwnerIfNone from "../hooks/useSetOwnerIfNone";
import InvitationsSettings from "../components/modal/InvitationsSettings";
import BalanceContext from "../contexts/BalanceContext";
import { posthog } from "posthog-js";
import AuthContext from "../contexts/AuthContext";

type Comparator = (a: Einvitation, b: Einvitation) => number;
function getComparator(sortColumn: string): Comparator {
  switch (sortColumn) {
    default:
      throw new Error(`unsupported sortColumn: "${sortColumn}"`);
  }
}

function CreateInvitations() {
  const { user } = React.useContext(AuthContext);
  const { id } = useParams<{ id: string }>();
  const [template, setTemplate] = React.useState<Template | null>(null);
  useSetOwnerIfNone(template, setTemplate);

  const [rows, setRows] = React.useState<Einvitation[]>([]);
  const [sortColumns, setSortColumns] = useState<readonly SortColumn[]>([]);
  const [selectedRows, setSelectedRows] = useState<ReadonlySet<string>>(
    () => new Set()
  );

  const selectedRowsIncludeSent = useMemo(() => {
    return rows.some((row) => {
      if (selectedRows.has(row.id)) {
        return row.events.some((e) => e.type === "SENT");
      }
      return false;
    });
  }, [rows, selectedRows]);

  const [showSettingsModal, setShowSettingsModal] = useState(false);
  const [showCreateInvitationsModal, setShowCreateInvitationsModal] =
    useState(false);

  const rsvpColumns = useMemo(() => {
    if (template == null) return [];
    return template.rsvp_questions.map((q) => ({
      key: q.question,
      name: q.question,
      formatter: function formatter({ row }: { row: Einvitation }) {
        let res = row.rsvp_answers[q.question];
        if (q.question in row.rsvp_answers) {
          res = row.rsvp_answers[q.question];
        } else {
          res = "";
        }
        return <div>{res}</div>;
      },
    }));
  }, [template]);

  const columns: readonly Column<Einvitation>[] = useMemo(
    () => [
      {
        ...SelectColumn,
        headerCellClass: selectCellClassname,
        cellClass: selectCellClassname,
      },
      { key: "recepient_name", name: "Recepient", editor: textEditor },
      { key: "recepient_email", name: "Email", editor: textEditor },
      {
        key: "events",
        name: "Status",
        formatter: function StatusFormatter({ row }: { row: Einvitation }) {
          if (row.events.length === 0)
            return (
              <div>
                <Icon className="text-muted">info</Icon> Unsent
              </div>
            );
          const status = row.events[row.events.length - 1].type;
          return (
            <div>
              {statusToIcon[status]} {capitalize(status)}
            </div>
          );
        },
      },
      { key: "email_title", name: "Email Title", editor: textEditor },
      { key: "email_contents", name: "Email Message", editor: textEditor },
      ...rsvpColumns,
    ],
    [rsvpColumns]
  );

  const updateEinvitation = async (einv: Einvitation) => {
    try {
      await axios.put(`/einvitations/${einv.id}/`, einv, DEFAULT_CONFIG);
      toast.success("Save successful");
    } catch (e: any) {
      toast.error("Failed to save invitation");
    }
  };

  React.useEffect(() => {
    const fetchEinvitations = async () => {
      try {
        const res: AxiosResponse<{ einvitations: Einvitation[] }> =
          await axios.get(`/einvitations/?template_id=${id}`, DEFAULT_CONFIG);
        setRows(res.data.einvitations);
      } catch (e: any) {
        toast.error(e);
      }
    };
    fetchEinvitations();
  }, [id]);

  const sortedRows = useMemo((): readonly Einvitation[] => {
    if (sortColumns.length === 0) return rows;

    return [...rows].sort((a, b) => {
      for (const sort of sortColumns) {
        const comparator = getComparator(sort.columnKey);
        const compResult = comparator(a, b);
        if (compResult !== 0) {
          return sort.direction === "ASC" ? compResult : -compResult;
        }
      }
      return 0;
    });
  }, [rows, sortColumns]);

  // fetch template info
  React.useEffect(() => {
    const fetchTemplate = async () => {
      try {
        const res: AxiosResponse<Template> = await axios.get(
          `/templates/${id}/`,
          DEFAULT_CONFIG
        );
        setTemplate(res.data);
      } catch (e: any) {
        toast.error("Failed to fetch template for that url");
      }
    };
    fetchTemplate();
  }, [id]);

  // save template if not changed for 1 second
  React.useEffect(() => {
    if (template == null) return;
    const timeout = setTimeout(() => {
      try {
        axios.put(`/templates/${template.id}/`, template, DEFAULT_CONFIG);
      } catch (e: any) {
        toast.error("Failed to save");
      }
    }, 1000);
    return () => clearTimeout(timeout);
  }, [template]);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const deleteInvitations = async () => {
    try {
      const deleteCount = selectedRows.size;
      const data = {
        einvitation_ids: Array.from(selectedRows),
        template_id: id,
      };
      const res: AxiosResponse<{ einvitations: Einvitation[] }> =
        await axios.delete("/einvitations/", { ...DEFAULT_CONFIG, data: data });
      setSelectedRows(new Set());
      toast.success(`Successfully deleted ${deleteCount} invitation(s)`);
      setRows(res.data.einvitations);
      setShowDeleteModal(false);
    } catch (e: any) {
      toast.error("Failed to delete invitations");
    }
  };

  const { balance, setBalance } = React.useContext(BalanceContext);

  const [showSendModal, setShowSendModal] = useState(false);
  const sendInvitations = async () => {
    try {
      const deleteCount = selectedRows.size;
      const data = {
        einvitation_ids: Array.from(selectedRows),
        event_type: "PENDING",
        template_id: id,
      };
      const res: AxiosResponse<{ einvitations: Einvitation[] }> =
        await axios.post("/einvitations/events/", data, DEFAULT_CONFIG);
      posthog.capture(SENT_INVITATIONS, { user: user.id });
      setSelectedRows(new Set());
      setRows(res.data.einvitations);
      toast.success(
        `Successfully initiated sending ${deleteCount} invitation(s)`
      );
      setBalance(balance - deleteCount * 100);
      setShowSendModal(false);
    } catch (e: any) {
      toast.error("Failed to send invitations");
    }
  };

  const sendInvitationsTooltipChildren = [];
  if (selectedRowsIncludeSent) {
    sendInvitationsTooltipChildren.push(
      <p>Cannot send invitations that have already been sent</p>
    );
  }
  const notEnoughCredits = balance / 100 < selectedRows.size;
  if (notEnoughCredits) {
    sendInvitationsTooltipChildren.push(
      <p>Not enough credits to send selected invitations</p>
    );
  }

  return (
    <Container className="py-5">
      <h1>Invitations</h1>
      {template && (
        <p>
          Invitations here are based on the template{" "}
          <Link to={`/templates/view/${id}`}>{template.name}</Link>
        </p>
      )}
      <div className="d-flex mb-3">
        <span className="align-self-end">Invitations ({rows.length})</span>
        <div className="ms-auto d-flex">
          <Button
            className="me-3"
            variant="secondary"
            onClick={() => {
              setShowSettingsModal(true);
            }}
          >
            <Icon>settings</Icon>
          </Button>
          {showSettingsModal && (
            <InvitationsSettings
              show={showSettingsModal}
              setShow={setShowSettingsModal}
              template={template}
              setTemplate={setTemplate}
            />
          )}
          <Dropdown className="me-3">
            <Dropdown.Toggle variant="secondary" id="dropdown-basic">
              Actions
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item onClick={() => setShowDeleteModal(true)}>
                Delete
              </Dropdown.Item>
              {showDeleteModal && (
                <ConfirmModal
                  onHide={() => setShowDeleteModal(false)}
                  confirm={deleteInvitations}
                  title="Delete invitations"
                  variant="danger"
                  confirmDescription={`delete ${selectedRows.size} invitation(s)`}
                  optionalDetails={
                    <ListGroup as="ol" numbered>
                      {rows
                        .filter((row) => selectedRows.has(row.id))
                        .map((row) => {
                          return (
                            <ListGroup.Item as="li" key={row.id}>
                              {row.recepient_name},{" "}
                              <em>{row.recepient_email}</em>
                            </ListGroup.Item>
                          );
                        })}
                    </ListGroup>
                  }
                />
              )}
              <OverlayTrigger
                overlay={
                  sendInvitationsTooltipChildren.length > 0 ? (
                    <Tooltip className="space-children-3">
                      {sendInvitationsTooltipChildren}
                    </Tooltip>
                  ) : (
                    <></>
                  )
                }
              >
                <span className="d-inline-block">
                  <Dropdown.Item
                    disabled={selectedRowsIncludeSent || notEnoughCredits}
                    onClick={() => setShowSendModal(true)}
                  >
                    Send invitation(s)
                  </Dropdown.Item>
                </span>
              </OverlayTrigger>
              {showSendModal && (
                <ConfirmModal
                  onHide={() => setShowSendModal(false)}
                  confirm={sendInvitations}
                  title="Send invitations"
                  variant="primary"
                  confirmText="Send"
                  confirmDescription={`send ${selectedRows.size} invitation(s)`}
                  optionalDetails={
                    <ListGroup as="ol" numbered>
                      {rows
                        .filter((row) => selectedRows.has(row.id))
                        .map((row) => {
                          return (
                            <ListGroup.Item as="li" key={row.id}>
                              {row.recepient_name},{" "}
                              <em>{row.recepient_email}</em>
                            </ListGroup.Item>
                          );
                        })}
                    </ListGroup>
                  }
                />
              )}
            </Dropdown.Menu>
          </Dropdown>
          <Button
            variant="primary"
            onClick={() => {
              setShowCreateInvitationsModal(true);
            }}
          >
            Create invitation(s)
          </Button>
          {showCreateInvitationsModal && (
            <CreateInvitationsGrid
              template={template}
              setShow={setShowCreateInvitationsModal}
              setInvitations={setRows}
            />
          )}
        </div>
      </div>
      <DataGrid<Einvitation, unknown, string>
        className="fill-grid rdg-light"
        columns={columns}
        rows={sortedRows}
        rowKeyGetter={(row) => row.id}
        onRowsChange={(newRows: Einvitation[]) => {
          setRows((prevRows) => {
            const prevIdToData = new Map(prevRows.map((row) => [row.id, row]));
            const updatedRows = newRows.filter((row) => {
              const prevRow = prevIdToData.get(row.id);
              return prevRow && JSON.stringify(prevRow) !== JSON.stringify(row);
            });
            // we expect one row to be updated at once, but loop just incase
            for (const row of updatedRows) {
              updateEinvitation(row);
            }
            return newRows;
          });
        }}
        sortColumns={sortColumns}
        onSortColumnsChange={setSortColumns}
        selectedRows={selectedRows}
        onSelectedRowsChange={setSelectedRows}
        renderers={{ sortStatus, checkboxFormatter }}
        direction={"ltr"}
      />
    </Container>
  );
}

export default CreateInvitations;
