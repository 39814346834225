import React from "react";
import { User } from "../types/identities";
import { EMPTY_USER } from "../constants";

export type AuthContextType = {
  user: User;
  setUser:
    | React.Dispatch<React.SetStateAction<User | null>>
    | ((u: User) => void);
  loading: boolean;
};

const AuthContext = React.createContext<AuthContextType>({
  user: EMPTY_USER,
  setUser: (u: User | null) => {
    throw new Error("No setUser function provided");
  },
  loading: true,
});

export default AuthContext;
