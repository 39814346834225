import React from "react";
import axios, { AxiosResponse } from "axios";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import AuthContext from "../../contexts/AuthContext";
import { WEB_NAME } from "../../constants";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { Offer } from "../../types/payments";

function SpecialOffer() {
  const [offer, setOffer] = React.useState<Offer | null>(null);
  const [show, setShow] = React.useState(false);
  const { user } = React.useContext(AuthContext);

  React.useEffect(() => {
    const fetchOffer = async () => {
      try {
        const res: AxiosResponse<Offer> = await axios.get("/payments/offer/");
        setOffer(res.data);
      } catch (err) {
        toast.error("Error fetching offer code");
      }
    };
    fetchOffer();
  }, []);

  React.useEffect(() => {
    setTimeout(() => {
      if (localStorage.getItem("should_show_offer") === "false") {
        return;
      }
      setShow(true);
    }, 5000);
  }, []);

  if (user || !show || !offer) {
    return null;
  }
  if (!offer.amount && !offer.percentage) {
    return null;
  }

  return (
    <Modal
      className="max-z"
      show={show}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      onHide={() => {
        setShow(false);
      }}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Limited time offer: <span>{offer.reason}</span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="space-children-4">
        <p>
          As a thank you for considering <em>{WEB_NAME}</em>, we are offering
          you{" "}
          {offer.amount ? (
            <strong>
              the first {Math.round(offer.amount / 100)} invitations for free.
            </strong>
          ) : (
            <span>a {offer.percentage}% discount.</span>
          )}
        </p>
        <p>
          To claim this offer, use{" "}
          <Link
            to={`/sign-up/?code=${offer.code}`}
            onClick={() => {
              localStorage.setItem("should_show_offer", "false");
              setShow(false);
            }}
          >
            this signup link
          </Link>
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="danger"
          onClick={() => {
            localStorage.setItem("should_show_offer", "false");
            setShow(false);
          }}
        >
          Reject
        </Button>
        <Link to={`/sign-up/?code=${offer.code}`}>
          <Button
            onClick={() => {
              localStorage.setItem("should_show_offer", "false");
              setShow(false);
            }}
          >
            Claim
          </Button>
        </Link>
      </Modal.Footer>
    </Modal>
  );
}

export default SpecialOffer;
