import React, { useState } from "react";
import DataGrid, { SelectColumn, Column, textEditor } from "react-data-grid";
import { toast } from "react-toastify";
import { Button, Modal } from "react-bootstrap";
import axios, { AxiosResponse } from "axios";

import { Einvitation, EinvitationCreationInput } from "../../types/einvitation";
import {
  selectCellClassname,
  sortStatus,
  checkboxFormatter,
} from "../tables/utils";
import { CREATE_INVITATIONS, DEFAULT_CONFIG } from "../../constants";
import { Template } from "../../types/template";
import { posthog } from "posthog-js";
import AuthContext from "../../contexts/AuthContext";

function createRows(): EinvitationCreationInput[] {
  const rows: EinvitationCreationInput[] = [];

  for (let i = 1; i < 500; i++) {
    rows.push({
      rowNumber: i,
      recepient_name: "",
      recepient_email: "",
    });
  }

  return rows;
}

function CreateInvitationsGrid({
  template,
  setShow,
  setInvitations,
}: {
  template: Template | null;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  setInvitations: React.Dispatch<React.SetStateAction<Einvitation[]>>;
}) {
  const { user } = React.useContext(AuthContext);

  const [rows, setRows] = useState<EinvitationCreationInput[]>(createRows);
  const [isPasting, setIsPasting] = useState(false);

  const [selectedCell, setSelectedCell] = useState<{
    rowNumber: number;
    columnKey: string;
  } | null>(null);

  const columns: readonly Column<EinvitationCreationInput>[] =
    React.useMemo(() => {
      const getFormatter = (
        rowName: "rowNumber" | "recepient_name" | "recepient_email"
      ) => {
        const formatter = ({
          row,
          isCellSelected,
        }: {
          row: EinvitationCreationInput;
          isCellSelected: boolean;
        }) => {
          if (isCellSelected) {
            const stateIsThisCell =
              selectedCell &&
              selectedCell.rowNumber === row.rowNumber &&
              selectedCell.columnKey === rowName;
            if (!stateIsThisCell) {
              // delay to prevent rerendering upon formatting
              setTimeout(() => {
                setSelectedCell({
                  rowNumber: row.rowNumber,
                  columnKey: rowName,
                });
              }, 100);
            }
          }
          return row[rowName];
        };

        return formatter;
      };

      return [
        {
          ...SelectColumn,
          headerCellClass: selectCellClassname,
          cellClass: selectCellClassname,
        },
        {
          key: "rowNumber",
          name: "Number",
          formatter: getFormatter("rowNumber"),
        },
        {
          key: "recepient_name",
          name: "Recepient",
          editor: textEditor,
          formatter: getFormatter("recepient_name"),
        },
        {
          key: "recepient_email",
          name: "Email",
          editor: textEditor,
          formatter: getFormatter("recepient_email"),
        },
      ];
    }, [selectedCell]);

  // paste from selected cell
  React.useEffect(() => {
    const onPaste = (e: ClipboardEvent) => {
      e.preventDefault();
      if (!e.clipboardData || !selectedCell) {
        return;
      }
      setIsPasting(true);
      const rowArrays = e.clipboardData
        .getData("text/plain")
        .split(/\r\n|\n|\r/)
        .map((row) => row.split("\t"));
      rowArrays.pop(); // remove last empty row

      const selectedCellColIdx = columns.findIndex(
        (col) => col.key === selectedCell.columnKey
      );

      setRows((prevRows) => {
        const newRows = [...prevRows];
        const startIdx = selectedCell.rowNumber - 1;
        const endIdx = startIdx + rowArrays.length;
        let error: string | null = null;
        for (let i = startIdx; i < endIdx; i++) {
          const row = newRows[i];
          if (!row) {
            error = "Paste is out of bounds";
            break;
          }
          for (
            let j = selectedCellColIdx;
            j < selectedCellColIdx + rowArrays[0].length;
            j++
          ) {
            const val = rowArrays[i - startIdx][j - selectedCellColIdx];
            const column = columns[j];
            if (!column) {
              error = "Paste is out of bounds";
              break;
            }
            row[column.key] = val;
          }
        }
        if (error) {
          toast.error(error);
        }

        setTimeout(() => {
          setIsPasting(false);
        }, 10);
        return newRows;
      });
    };

    document.addEventListener("paste", onPaste);
    return () => document.removeEventListener("paste", onPaste);
  }, [columns, selectedCell]);

  const createInvitations = async () => {
    const validRows = rows.filter(
      (row) => row.recepient_name || row.recepient_email
    );
    if (validRows.length === 0) {
      toast.error("No valid rows. Valid rows have either a name or email");
      return;
    }
    if (!template) {
      toast.error("Error loading template");
      return;
    }
    try {
      const res: AxiosResponse<{ einvitations: Einvitation[] }> =
        await axios.post(
          "/einvitations/",
          { template: template, einvitations: validRows },
          DEFAULT_CONFIG
        );
      setInvitations(res.data.einvitations);
      posthog.capture(CREATE_INVITATIONS, { user: user.id });
      toast.success("Invitations created");
      setShow(false);
    } catch (err) {
      toast.error("Error creating invitations");
    }
  };

  if (isPasting) {
    return null;
  }
  return (
    <Modal show={true} onHide={() => setShow(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Create Invitation(s)</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <DataGrid<EinvitationCreationInput, unknown, number>
          className="fill-grid rdg-light"
          columns={columns}
          rows={rows}
          onRowsChange={setRows}
          rowKeyGetter={(row) => row.rowNumber}
          renderers={{ sortStatus, checkboxFormatter }}
          direction={"ltr"}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setShow(false)}>
          Close
        </Button>
        <Button variant="primary" onClick={createInvitations}>
          Create
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default CreateInvitationsGrid;
