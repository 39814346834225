import React from "react";
import { posthog } from "posthog-js";
import { Alert, Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import { ADDED_CREDITS } from "../constants";
import AuthContext from "../contexts/AuthContext";

function PaymentSuccessful() {
  const [searchParams, setSearchParams] = useSearchParams();

  const payment_intent = searchParams.get("payment_intent") || "";
  const payment_intent_client_secret =
    searchParams.get("payment_intent_client_secret") || "";
  const redirect_status = searchParams.get("redirect_status") || "";

  const { user } = React.useContext(AuthContext);
  React.useEffect(() => {
    const addedCredits = async () => {
      posthog.capture(ADDED_CREDITS, { user: user.id });
    };
    addedCredits();
  }, [user.id]);

  return (
    <Container className="pt-5">
      <Row className="d-flex justify-content-center">
        <Col xs={12} sm={10} md={8} lg={6}>
          <Alert
            variant={redirect_status === "succeeded" ? "success" : "danger"}
          >
            <Alert.Heading>Payment Successful</Alert.Heading>
            <p className="mt-3">
              You can use your new credits to send invitations
            </p>
            <p>
              Go to your <Link to="/profile">profile</Link> to see your new
              credit balance
            </p>
          </Alert>
        </Col>
      </Row>
    </Container>
  );
}
export default PaymentSuccessful;
