import React, { useState } from "react";
import { Button, Card, Container, Form } from "react-bootstrap";
import { toast } from "react-toastify";
import Alert from "react-bootstrap/Alert";
import axios, { AxiosResponse } from "axios";

import { DEFAULT_CONFIG } from "../constants";
import { User } from "../types/identities";
import AuthContext from "../contexts/AuthContext";
import { Link } from "react-router-dom";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [hidePassword, setHidePassword] = useState(true);

  const { setUser } = React.useContext(AuthContext);

  const [awaitingLogin, setAwaitingLogin] = useState(false);
  const [loginSuccess, setLoginSuccess] = useState(false);
  const login = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setAwaitingLogin(true);
    try {
      const res: AxiosResponse<{ user: User }> = await axios.post(
        "/identities/login/",
        {
          email: email,
          password: password,
        },
        DEFAULT_CONFIG
      );
      setUser(res.data.user);
      setLoginSuccess(true);
      toast.success("Login successful");
    } catch (e: any) {
      toast.error("Error logging in");
    }
    setAwaitingLogin(false);
  };

  return (
    <Container className="py-5">
      <Card
        className="py-3 px-3"
        style={{ width: "18rem", maxWidth: "90%", margin: "auto" }}
      >
        <Form onSubmit={login} className="space-children-4">
          <h1>Login</h1>
          <Form.Group controlId="formEmail">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="text"
              value={email}
              autoComplete="username" // did not do email as google password manager expects field to be called username
              onChange={(event) => setEmail(event.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="formPassword">
            <Form.Label>Password</Form.Label>
            <div className="d-flex justify-content-end">
              <Button
                variant="link"
                className="p-0 text-decoration-none text-muted"
                onClick={() => setHidePassword(!hidePassword)}
              >
                {hidePassword ? "Show password" : "Hide password"}
              </Button>
            </div>
            <Form.Control
              type={hidePassword ? "password" : "text"}
              value={password}
              autoComplete="current-password"
              onChange={(event) => setPassword(event.target.value)}
            />
          </Form.Group>

          {loginSuccess ? (
            <Alert variant="success">Login successful</Alert>
          ) : (
            <div className="d-grid gap-2">
              <Button variant="primary" type="submit" disabled={awaitingLogin}>
                {awaitingLogin ? "Logging in..." : "Login"}
              </Button>
            </div>
          )}
        </Form>
        <p className="mt-4">
          Need to create an account? <Link to="/sign-up">Sign up</Link>
        </p>
      </Card>
    </Container>
  );
}

export default Login;
