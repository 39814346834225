import { CheckboxFormatterProps, SortStatusProps } from "react-data-grid";

import { css } from "@emotion/css";

export const selectCellClassname = css`
  display: flex;
  align-items: center;
  justify-content: center;

  > input {
    margin: 0;
  }
`;

const sortPriorityClassname = css`
  color: grey;
  margin-left: 2px;
`;

export function checkboxFormatter(
  { onChange, ...props }: CheckboxFormatterProps,
  ref: React.RefObject<HTMLInputElement>
) {
  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    onChange(e.target.checked, (e.nativeEvent as MouseEvent).shiftKey);
  }

  return <input type="checkbox" ref={ref} {...props} onChange={handleChange} />;
}

export function sortStatus({ sortDirection, priority }: SortStatusProps) {
  return (
    <>
      {sortDirection !== undefined
        ? sortDirection === "ASC"
          ? "\u2B9D"
          : "\u2B9F"
        : null}
      <span className={sortPriorityClassname}>{priority}</span>
    </>
  );
}
