import React from "react";
import { Team } from "../../types/identities";
import { Card, ListGroup } from "react-bootstrap";
import { toast } from "react-toastify";
import axios, { AxiosResponse } from "axios";
import { DEFAULT_CONFIG } from "../../constants";

function TeamMembers() {
  const [team, setTeam] = React.useState<Team | null>(null);

  React.useEffect(() => {
    const fetchTeam = async () => {
      try {
        const res: AxiosResponse<{ team: Team }> = await axios.get(
          "/identities/team/",
          DEFAULT_CONFIG
        );
        setTeam(res.data.team);
      } catch (e: any) {
        toast.error("Error fetching team");
      }
    };
    fetchTeam();
  }, []);

  if (!team) {
    return <div></div>;
  }
  return (
    <Card>
      <Card.Body>
        <Card.Title>Team members</Card.Title>
        <ListGroup>
          {team.users.map((user) => (
            <ListGroup.Item key={user.id}>
              <p className="mb-0">{user.email}</p>
            </ListGroup.Item>
          ))}
        </ListGroup>
      </Card.Body>
    </Card>
  );
}

export default TeamMembers;
