import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

function Guarantee() {
  return (
    <Container className="pt-5">
      <Row>
        <Col xs={12} sm={8} md={7} lg={6}>
          <h1>Guarantee</h1>
          <p>
            We are confident that our service will exceed your expectations and
            make planning your event a breeze.
          </p>
          <p>
            If for any reason you are not completely satisfied with our service,
            just let us know within 30 days of your purchase and we will issue a
            full refund. No questions asked.
          </p>
          <p>
            We stand behind our service and want you to feel confident in your
            purchase. Try our service risk-free today!
          </p>
          <p>
            <Link to="/">Go to the template creation page</Link>
          </p>
        </Col>
      </Row>
    </Container>
  );
}

export default Guarantee;
