import React from "react";

import { Content, Element } from "../../types/content";
import { EditText, ViewText } from "./Text";

export function ViewElement({ content }: { content: Content }) {
  switch (content.element.type) {
    case "TEXT":
      return <ViewText size={content.size} element={content.element} />;
    case "IMAGE":
      return (
        <img
          width={content.size.width}
          src={content.element.image.url}
          alt={content.element.image.alt}
        />
      );
    case "VIDEO":
      return (
        <video
          src={content.element.video.url}
          controls
          width={content.size.width}
        />
      );
    default:
      return <span>Unknown content type</span>;
  }
}

export function EditElement({
  content,
  setElement,
}: {
  content: Content;
  setElement: (el: Element) => void;
}) {
  switch (content.element.type) {
    case "TEXT":
      return (
        <EditText
          size={content.size}
          element={content.element}
          setElement={setElement}
        />
      );
    case "IMAGE":
      return (
        <img
          width={content.size.width}
          src={content.element.image.url}
          alt={content.element.image.alt}
        />
      );
    case "VIDEO":
      return (
        <video
          src={content.element.video.url}
          controls
          width={content.size.width}
        />
      );
    default:
      return <span>Unknown content type</span>;
  }
}
