import React, { useCallback } from "react";

import { Content } from "../../types/content";
import { Rnd } from "react-rnd";
import DeleteButton from "../buttons/DeleteButton";
import { EditElement, ViewElement } from "./Element";

export function ViewContent({ content }: { content: Content }) {
  return (
    <Rnd
      size={{ width: content.size.width, height: content.size.height }}
      position={{
        x: content.position.x,
        y: content.position.y,
      }}
      disableDragging={true}
      enableResizing={false}
      style={{ zIndex: content.position.z }}
    >
      <ViewElement content={content} />
    </Rnd>
  );
}

export function EditContent({
  content,
  setContent,
  deleteContent,
}: {
  content: Content;
  setContent: (content: Content) => void;
  deleteContent: (content: Content) => void;
}) {
  const handleDrag = (e: any, data: any) => {
    e.stopPropagation();
    const newContent = {
      ...content,
      position: { ...content.position, x: data.x, y: data.y },
    };
    setContent(newContent);
  };

  const handleResize = (e: any, direction: any, ref: any, d: any) => {
    e.stopPropagation();
    const newContent = {
      ...content,
      size: {
        width: content.size.width + d.width,
        height: content.size.height + d.height,
      },
    };
    setContent(newContent);
  };

  const setElement = useCallback(
    (element: any) => {
      if (!setContent) {
        return;
      }
      const newContent = { ...content, element };
      setContent(newContent);
    },
    [content, setContent]
  );

  return (
    <Rnd
      size={{ width: content.size.width, height: content.size.height }}
      position={{
        x: content.position.x,
        y: content.position.y,
      }}
      onDragStop={handleDrag}
      onResizeStop={handleResize}
      style={{ border: "1px dashed grey", zIndex: content.position.z }}
    >
      <DeleteButton confirmDelete={() => deleteContent(content)} />
      <EditElement content={content} setElement={setElement} />
    </Rnd>
  );
}
