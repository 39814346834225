import { v4 as uuid4 } from "uuid";

export const DEFAULT_CONFIG = {
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
  },
};

export const FILE_UPLOAD_CONFIG = {
  headers: {
    "Content-Type": "multipart/form-data",
  },
};

export const GOLDEN_RATIO = 1.61803398875;
export const INVITATION_PERCENT_WIDTH = 0.7;

export const WEB_NAME = "Just Wedding Invites";

export const PROFILE_PAGE = "/profile";

export const BOOTSTRAP_BREAKPOINTS = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1400,
};

// posthog events
export const SEE_TEMPLATES = "see_templates";
export const CREATE_TEMPLATE = "create_template";
export const EDIT_TEMPLATE = "edit_template";
export const PREVIEW_TEMPLATE = "preview_template";
export const SIGN_UP = "sign_up";
export const CREATE_INVITATIONS = "create_invitations";
export const CREATE_PAYMENT_INTENT = "create_payment_intent";
export const ADDED_CREDITS = "added_credits";
export const SENT_INVITATIONS = "sent_invitations";

export const EMPTY_USER = {
  id: uuid4(),
  email: "",
  first_name: "",
  last_name: "",
  team_id: "",
};

let FRONTEND_URL = "http://localhost:3000";
let STRIPE_PUBLISHABLE_KEY =
  "pk_test_51MQidOJxoZyyDh9Ir0NSUieb70z8Bx6djbIjNntMbpyq0XaK2pNLXlK0UmwMzu6k2jyBbt1eXph9xP9JN3codU9D00GxN3y0XI";
if (process.env.REACT_APP_BUILD_ENV === "production") {
  FRONTEND_URL = "https://justweddinginvites.com";
  STRIPE_PUBLISHABLE_KEY =
    "pk_live_51MQidOJxoZyyDh9I3F4N1c5sfNBGvoQKckcM0e6B8FHd6hz2qfOVm7nOuMFo5eRlLZF4zESeD6coMDlp6iAo2BU400ilnwb0jt";
}

export { FRONTEND_URL, STRIPE_PUBLISHABLE_KEY };
