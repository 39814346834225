import React from "react";
import axios, { AxiosResponse } from "axios";
import { useParams, useSearchParams } from "react-router-dom";
import { useSpring, animated } from "@react-spring/web";
import { DEFAULT_CONFIG } from "../constants";
import { toast } from "react-toastify";
import { Button, Card, Col, Container, ListGroup, Row } from "react-bootstrap";
import EinvitationTemplateView from "../components/einvtemplateshared/EinvitationTemplateView";
import useElWidth from "../hooks/useElWidth";
import EinvitationTemplateViewShell from "../components/einvtemplateshared/EinvitationTemplateViewShell";
import RsvpForm from "../components/forms/RsvpForm";
import { EinvitationIndividual } from "../types/einvitation";
import AuthContext from "../contexts/AuthContext";
import { User } from "../types/identities";
import { capitalize, parseDjangoDate } from "../utils/text";
import { backendEventToFrontend } from "../utils/events";
import { Icon } from "../components/SlateComponents";

const parseCreatedBecause = (
  createdBecause: string,
  currentUser: User | null
) => {
  if (createdBecause === "backend") {
    return "app logic";
  }
  if (!currentUser) {
    return "actions by others";
  }
  if (createdBecause === currentUser.id) {
    return "actions by you";
  }
  return "actions by others";
};
function EinvitationPage() {
  const { id } = useParams<{ id: string }>();
  const { user } = React.useContext(AuthContext);
  const divRef = React.useRef<HTMLDivElement>(null);
  const width = useElWidth(divRef);
  const [searchParams, setSearchParams] = useSearchParams();
  const springs = useSpring({
    delay: 1000,
    from: { top: "110vh", left: "-1000px" },
    to: { top: "0px", left: "0px" },
    config: {
      duration: 500, // duration and tension seem to do the same thing, but it looks slightly cooler with tension values < 100
      tension: 100,
      friction: 20,
    },
  });

  const token = searchParams.get("token") || "";

  const [showRSVP, setShowRSVP] = React.useState(false);
  const { transform, opacity } = useSpring({
    opacity: showRSVP ? 1 : 0,
    transform: `perspective(600px) rotateX(${showRSVP ? 180 : 0}deg)`,
    config: { mass: 5, tension: 500, friction: 80 },
  });

  const [einvitation, setEinvitation] =
    React.useState<EinvitationIndividual | null>(null);
  React.useEffect(() => {
    const fetchEinvitation = async () => {
      try {
        const res: AxiosResponse<EinvitationIndividual> = await axios.get(
          `/einvitations/${id}/?token=${token}`,
          DEFAULT_CONFIG
        );
        setEinvitation(res.data);
      } catch (err) {
        toast.error("Something went wrong. Please refresh the page.");
      }
    };
    fetchEinvitation();
  }, [id, token]);

  // debounced save einvitation changes
  const einvitationString = JSON.stringify(einvitation);
  React.useEffect(() => {
    const saveEinvitation = async () => {
      try {
        await axios.put(
          `/einvitations/${id}/?token=${token}`,
          einvitation,
          DEFAULT_CONFIG
        );
      } catch (err) {
        toast.error("Something went wrong. Please refresh the page.");
      }
    };
    const timeout = setTimeout(() => {
      saveEinvitation();
    }, 1000);
    return () => clearTimeout(timeout);
  }, [einvitationString, einvitation, id, token]);

  if (!einvitation) {
    return <div></div>;
  }

  return (
    <div className="pt-3 pb-5 text-center">
      <Container>
        <Row className="gy-5">
          <Col xs={12} md={8} lg={9}>
            <Row className="justify-content-center position-relative">
              <Col xs={12} className="position-relative">
                <div className="w-100" ref={divRef}>
                  <animated.div
                    style={{
                      width: "100%", // needed by children to compute width
                      position: "relative", // needed for animation
                      ...springs,
                    }}
                  >
                    <animated.div
                      className="position-relative top-0"
                      style={{ opacity: opacity.to((o) => 1 - o), transform }}
                    >
                      <EinvitationTemplateView
                        data={einvitation}
                        width={width}
                      />
                    </animated.div>
                    <animated.div
                      className="position-absolute top-0"
                      style={{
                        opacity,
                        transform,
                        rotateX: "180deg",
                      }}
                    >
                      <EinvitationTemplateViewShell
                        data={{ ...einvitation, background_color: "#ffffff" }}
                        width={width}
                      >
                        {() => (
                          <RsvpForm
                            template={einvitation.template}
                            einvitation={einvitation}
                            setEinvitation={setEinvitation}
                          />
                        )}
                      </EinvitationTemplateViewShell>
                    </animated.div>
                  </animated.div>
                </div>
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col xs={12}>
                <Button
                  className="mt-3 w-100"
                  variant={showRSVP ? "secondary" : "primary"}
                  // style={{ width: width }}
                  onClick={() => setShowRSVP((state) => !state)}
                >
                  {showRSVP ? "See Invitation" : "RSVP"}
                </Button>
              </Col>
            </Row>
          </Col>
          <Col xs={12} md={4} lg={3}>
            <Card className="text-start">
              <Card.Body>
                <Card.Title className="d-flex align-items-center" as="h4">
                  <Icon style={{ fontSize: "1.5rem", marginRight: "0.5rem" }}>
                    change_history
                  </Icon>
                  Events
                </Card.Title>

                <ListGroup>
                  {einvitation.events.map((event) => (
                    <ListGroup.Item key={event.id}>
                      <h3 className="h5">
                        {capitalize(backendEventToFrontend[event.type])}
                      </h3>
                      <em className="text-muted">
                        {parseDjangoDate(event.created_at)}
                      </em>
                      {/* <p>
                        Created because of{" "}
                        <em>
                          {parseCreatedBecause(event.created_because, user)}
                        </em>
                      </p> */}
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default EinvitationPage;
