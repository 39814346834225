import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import StripeCheckoutForm from "./StripeCheckoutForm";
import axios, { AxiosResponse } from "axios";
import {
  CREATE_PAYMENT_INTENT,
  DEFAULT_CONFIG,
  STRIPE_PUBLISHABLE_KEY,
} from "../../constants";
import { toast } from "react-toastify";
import "./StripeWrapper.scss";
import AuthContext from "../../contexts/AuthContext";
import { posthog } from "posthog-js";

const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY);

function StripeWrapper({ amount_in_cents }: { amount_in_cents: number }) {
  const [clientSecret, setClientSecret] = useState("");
  const { user } = React.useContext(AuthContext);

  // Create PaymentIntent as soon as the page loads
  useEffect(() => {
    const createPaymentIntent = async () => {
      try {
        const data = {
          amount_in_cents: amount_in_cents,
        };
        const res: AxiosResponse<{ clientSecret: string }> = await axios.post(
          "/payments/stripe/payment-intent/",
          data,
          DEFAULT_CONFIG
        );
        setClientSecret(res.data.clientSecret);
        posthog.capture(CREATE_PAYMENT_INTENT, { user: user.id });
      } catch (err: any) {
        toast.error("Error creating payment intent. Please try again later.");
      }
    };

    createPaymentIntent();
  }, [amount_in_cents, user.id]);

  const appearance = {
    theme: "stripe",
  };
  const options = {
    clientSecret,
    appearance,
  } as any;
  if (!clientSecret) return <div></div>;

  return (
    <div id="stripe-wrapper">
      <Elements options={options} stripe={stripePromise}>
        <StripeCheckoutForm />
      </Elements>
    </div>
  );
}

export default StripeWrapper;
