import { Col, Container, Row } from "react-bootstrap";
import ProfileSidebar from "../components/sidebars/ProfileSideBar";
import InviteToTeam from "../components/team/InviteToTeam";
import TeamMembers from "../components/team/TeamMembers";

function Team() {
  return (
    <div className="d-flex">
      <ProfileSidebar />
      <Container className="pt-5">
        <h1 style={{ display: "none" }}>Team</h1>
        <Row>
          <Col xs={12} md={6}>
            <InviteToTeam />
          </Col>
          <Col xs={12} md={6}>
            <TeamMembers />
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Team;
