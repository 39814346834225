import React from "react";
import { Accordion, Button, Card, Col, Form, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { numToBase64 } from "../../utils/numbers";
import BalanceBadge from "../payments/BalanceBadge";

function AddCredits() {
  const [customAmount, setCustomAmount] = React.useState(10);

  return (
    <Card>
      <Card.Header className="d-flex">
        <span>Credits</span>
        <BalanceBadge className="ms-auto d-flex align-items-center" />
      </Card.Header>
      <Card.Body>
        <div role="group" className="mt-4">
          {[20, 50, 100, 200].map((amount) => (
            <Link to={`/make-payment/${numToBase64(amount * 100)}`}>
              <Button
                className="me-3 mb-3"
                variant={amount === 100 ? "primary" : "secondary"}
              >
                ${amount}
              </Button>
            </Link>
          ))}
        </div>
        <Accordion>
          <Accordion.Item eventKey="0">
            <Accordion.Header>Customize amount</Accordion.Header>
            <Accordion.Body>
              <div className="mt-4">
                <Row>
                  <Col>
                    <Form.Group>
                      <Form.Label>Custom amount ($USD)</Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="Enter amount"
                        value={customAmount}
                        onChange={(e) =>
                          setCustomAmount(parseInt(e.target.value))
                        }
                        isInvalid={customAmount < 10}
                      />
                      <Form.Control.Feedback type="invalid">
                        Amount must be no less than $10
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col className="d-flex align-items-end justify-content-end">
                    <Link
                      to={`/make-payment/${numToBase64(customAmount * 100)}`}
                    >
                      <Button variant="primary" className="mt-2">
                        Purchase
                      </Button>
                    </Link>
                  </Col>
                </Row>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Card.Body>
    </Card>
  );
}

export default AddCredits;
