import React from "react";
import useElWidth from "../../hooks/useElWidth";
import EinvitationTemplateContainer from "./EinvitationTemplateContainer";
import { EinvitationTemplateSharedData } from "../../types/shared";

function EinvitationTemplateViewShell({
  data,
  width,
  children,
}: {
  data: EinvitationTemplateSharedData;
  children: ({
    widthMultiplicationFactor,
  }: {
    widthMultiplicationFactor: number;
  }) => JSX.Element;
  width?: number;
}) {
  const ref = React.useRef<HTMLDivElement>(null);
  const parentWidth = useElWidth(ref);

  let widthMultiplicationFactor: number;
  if (width) {
    widthMultiplicationFactor = width / data.size.width;
  } else {
    widthMultiplicationFactor = parentWidth / data.size.width;
  }

  return (
    <div className="w-100" ref={ref}>
      <EinvitationTemplateContainer
        isEditing={true}
        style={{
          margin: "auto",
          width: `${data.size.width * widthMultiplicationFactor}px`,
          height: `${data.size.height * widthMultiplicationFactor}px`,
          backgroundColor: data.background_color,
        }}
      >
        {children({ widthMultiplicationFactor: widthMultiplicationFactor })}
      </EinvitationTemplateContainer>
    </div>
  );
}

export default EinvitationTemplateViewShell;
