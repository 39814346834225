import React from "react";
import * as RPS from "react-pro-sidebar";
import { Icon } from "../SlateComponents";
import { NavLink } from "react-router-dom";

function ProfileSidebar() {
  return (
    <RPS.Sidebar className="pt-5" breakPoint="md" backgroundColor="#f7f7f7">
      <RPS.Menu>
        <RPS.MenuItem
          icon={<Icon>account_circle</Icon>}
          component={<NavLink to="/profile" />}
        >
          Profile
        </RPS.MenuItem>
        <RPS.MenuItem
          icon={<Icon>groups</Icon>}
          component={<NavLink to="/team" />}
        >
          Team
        </RPS.MenuItem>
        <RPS.MenuItem
          icon={<Icon>credit_card</Icon>}
          component={<NavLink to="/payments" />}
        >
          Payments
        </RPS.MenuItem>
        {/* <RPS.MenuItem
          icon={<Icon>security</Icon>}
          component={<NavLink to="/team" />}
        >
          Security
        </RPS.MenuItem> */}
      </RPS.Menu>
    </RPS.Sidebar>
  );
}

export default ProfileSidebar;
