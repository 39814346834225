import React, { useState } from "react";
import { Alert, Badge, Button, Card, Container, Form } from "react-bootstrap";
import { toast } from "react-toastify";
import axios, { AxiosResponse } from "axios";
import validator from "email-validator";

import AuthContext from "../contexts/AuthContext";
import { DEFAULT_CONFIG, SIGN_UP } from "../constants";
import { User } from "../types/identities";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { posthog } from "posthog-js";
import LogRocket from "logrocket";

function SignUp() {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const offerCode = searchParams.get("code") || "";
  const templateId = searchParams.get("template_id") || "";
  const teamJoinCode = searchParams.get("team_join_code") || "";
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  // password visibility
  const [hidePassword, setHidePassword] = useState(true);

  const { user, setUser } = React.useContext(AuthContext);

  // debounced, check api for whether email already exists
  const [checkedEmailAvail, setCheckedEmailAvail] = useState(false);
  const [emailError, setEmailError] = useState("");
  React.useEffect(() => {
    const checkEmail = async () => {
      if (email === "") {
        return;
      }
      try {
        const res: AxiosResponse<{ exists: boolean; message: string }> =
          await axios.get(`/identities/email/?email=${email}`, DEFAULT_CONFIG);
        if (res.data.exists) {
          setEmailError(res.data.message);
        } else {
          setEmailError("");
        }
      } catch (err) {
        toast.error("Something went wrong. Please refresh the page.");
      }
      setCheckedEmailAvail(true);
    };
    setCheckedEmailAvail(false);
    // check email after 1 second of no email typing
    const timeoutId = setTimeout(() => {
      checkEmail();
    }, 1000);
    return () => clearTimeout(timeoutId);
  }, [email]);

  const [awaitingSignupResponse, setAwaitingSignupResponse] = useState(false);
  const [signupSuccess, setSignupSuccess] = useState(false);
  const signUp = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setAwaitingSignupResponse(true);
    try {
      const res: AxiosResponse<{ user: User }> = await axios.post(
        "/identities/user/",
        {
          id: user.id,
          email: email,
          password: password,
          offer_code: offerCode,
          team_join_code: teamJoinCode,
        },
        DEFAULT_CONFIG
      );
      posthog.capture(SIGN_UP, { user: user.id });
      setSignupSuccess(true);
      setUser(res.data.user);
      LogRocket.identify(res.data.user.id, {
        name: `${res.data.user.first_name} ${res.data.user.last_name}`,
        email: res.data.user.email,
      });
      if (templateId) {
        navigate(`/templates/create_invitations/${templateId}`);
      } else {
        navigate(`/profile`);
      }
    } catch (e: any) {
      toast.error("Error signing up");
    }
    setAwaitingSignupResponse(false);
  };

  return (
    <Container className="pt-5 pb-5">
      <Card
        className="py-3 px-3"
        style={{ width: "18rem", maxWidth: "90%", margin: "auto" }}
      >
        <Form onSubmit={signUp} className="space-children-4">
          <h1>Sign up</h1>
          <Form.Group controlId="formEmail">
            <Form.Label>Email</Form.Label>

            <Form.Control
              type="text"
              value={email}
              autoComplete="username" // did not do email as google password manager expects field to be called username
              onChange={(event) => setEmail(event.target.value)}
              isInvalid={checkedEmailAvail && emailError !== ""}
              isValid={
                validator.validate(email) &&
                checkedEmailAvail &&
                emailError === ""
              }
            />
            <Form.Control.Feedback type="invalid">
              {emailError}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="formPassword">
            <Form.Label>Password</Form.Label>
            <div className="d-flex justify-content-end">
              <Button
                variant="link"
                className="p-0 text-decoration-none text-muted"
                onClick={() => setHidePassword(!hidePassword)}
              >
                {hidePassword ? "Show password" : "Hide password"}
              </Button>
            </div>
            <Form.Control
              type={hidePassword ? "password" : "text"}
              value={password}
              autoComplete="current-password"
              onChange={(event) => setPassword(event.target.value)}
            />
          </Form.Group>

          {offerCode && (
            <Badge bg="secondary" className="mb-3">
              Offer code: {offerCode}
            </Badge>
          )}

          {signupSuccess ? (
            <Alert variant="success">Sign up successful!</Alert>
          ) : (
            <div className="d-grid gap-2">
              <Button
                variant="primary"
                type="submit"
                disabled={awaitingSignupResponse}
              >
                {awaitingSignupResponse ? "Signing up..." : "Sign up"}
              </Button>
            </div>
          )}
        </Form>
        <p className="mt-4">
          Do you already have an account? <Link to="/login">Log in</Link>
        </p>
      </Card>
    </Container>
  );
}

export default SignUp;
