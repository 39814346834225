import React from "react";
import { AxiosResponse } from "axios";
import { Button } from "react-bootstrap";
import axios from "axios";

import AuthContext from "../../contexts/AuthContext";
import { DEFAULT_CONFIG, EMPTY_USER } from "../../constants";
import { toast } from "react-toastify";

function LogoutButton() {
  const { setUser } = React.useContext(AuthContext);

  const logout = async () => {
    try {
      const response: AxiosResponse<{ message: string }> = await axios.delete(
        "/identities/logout/",
        DEFAULT_CONFIG
      );
      if (response.status === 200) {
        toast.success(response.data.message);
        setUser(EMPTY_USER);
      }
    } catch (e: any) {
      console.error(e);
    }
  };

  return (
    <Button variant="danger" type="button" onClick={logout}>
      Logout
    </Button>
  );
}

export default LogoutButton;
