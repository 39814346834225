import React from "react";
import { Content } from "../../types/content";
import { ViewContent } from "../content/Content";
import { EinvitationTemplateSharedData } from "../../types/shared";
import EinvitationTemplateViewShell from "./EinvitationTemplateViewShell";
import { Template } from "../../types/template";

function EinvitationTemplateView({
  data,
  width,
}: {
  data: EinvitationTemplateSharedData;
  width?: number;
}) {
  return (
    <EinvitationTemplateViewShell data={data} width={width}>
      {({ widthMultiplicationFactor }) => (
        <>
          {data.contents.map((content: Content) => {
            return (
              <ViewContent
                key={content.id}
                content={{
                  ...content,
                  size: {
                    width: content.size.width * widthMultiplicationFactor,
                    height: content.size.height * widthMultiplicationFactor,
                  },
                  position: {
                    z: content.position.z,
                    x: content.position.x * widthMultiplicationFactor,
                    y: content.position.y * widthMultiplicationFactor,
                  },
                }}
              />
            );
          })}
        </>
      )}
    </EinvitationTemplateViewShell>
  );
}

export function TemplateUnmodifiableParent({
  template,
}: {
  template: Template;
}) {
  return (
    <div className="cursor-pointer">
      <EinvitationTemplateView data={template} />
    </div>
  );
}

export default EinvitationTemplateView;
