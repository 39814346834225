import React from "react";

import "./EinvitationTemplateContainer.scss";

interface TemplateContainerProps {
  isEditing: boolean;
  createText?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  containerRef?: React.RefObject<HTMLDivElement>;
  children: React.ReactNode;
  // allow ...props to be passed in
  [x: string]: any;
}

function TemplateContainer({
  isEditing,
  createText,
  containerRef,
  children,
  ...props
}: TemplateContainerProps) {
  return (
    <div
      className="template-container"
      onMouseDown={isEditing ? createText : undefined}
      ref={containerRef}
      {...props}
    >
      {children}
    </div>
  );
}

export default TemplateContainer;
