import React from "react";
import { Col, Row, Container, Button } from "react-bootstrap";
import posthog from "posthog-js";

import axios, { AxiosResponse } from "axios";
import { toast } from "react-toastify";

import { Template } from "../types/template";
import { CREATE_TEMPLATE, DEFAULT_CONFIG, SEE_TEMPLATES } from "../constants";

import { Link, useNavigate } from "react-router-dom";
import { TemplateUnmodifiableParent } from "../components/einvtemplateshared/EinvitationTemplateView";
import AuthContext from "../contexts/AuthContext";

function CreateTemplate() {
  const { user } = React.useContext(AuthContext);
  const navigate = useNavigate();

  const createTemplate = async (template: Template) => {
    try {
      const res: AxiosResponse<Template> = await axios.post(
        "/templates/",
        template,
        DEFAULT_CONFIG
      );
      posthog.capture(CREATE_TEMPLATE, { user: user.id });
      const targetUrl = `/templates/change/${res.data.id}`;
      navigate(targetUrl);
    } catch (error) {
      // Handle any errors
      toast.error(`Error creating template ${error}`);
    }
  };

  const [templates, setTemplates] = React.useState<Template[]>([]);
  React.useEffect(() => {
    const fetchTemplates = async () => {
      try {
        const res: AxiosResponse<{ templates: Template[] }> = await axios.get(
          "/templates/",
          DEFAULT_CONFIG
        );
        setTemplates(res.data.templates);
        posthog.capture(SEE_TEMPLATES, { user: user.id });
      } catch (error) {
        // Handle any errors
        toast.error(`Error fetching templates ${error}`);
      }
    };

    fetchTemplates();
  }, []);

  return (
    <div className="pt-5">
      <h1 className="text-center mb-5">Choose a template</h1>
      <Container>
        <Row className="g-5">
          {templates.map((template) => (
            <Col xs={12} md={4} key={template.id}>
              <p className="text-center mb-4">{template.name}</p>
              <Button
                variant="outline-primary"
                className="w-100"
                onClick={() => {
                  createTemplate(template);
                }}
              >
                <TemplateUnmodifiableParent template={template} />
              </Button>
            </Col>
          ))}
        </Row>
        <p className="text-center mt-5">
          Read our <Link to="/guarantee">money back guarantee</Link>
        </p>
      </Container>
    </div>
  );
}

export default CreateTemplate;
